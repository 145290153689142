// import { useLocation } from 'react-router-dom';
import { createStyles } from '@mantine/core';
import { Logo, mediaQueries } from '@artsteps/components';
import ROUTES from '../../server/endpoints/routes';
import Copyright from '../../common/footer/Copyright';
import { useTranslation } from '../../infrastructure/translation/TranslationProvider';
import SocialMedia from '../../common/footer/SocialMedia';
import PagesInfo from '../../common/footer/PagesInfo';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    bottom: 0,
    width: '100%',
    minHeight: '540px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.typography[7],
    padding: '64px 40px 40px 40px',

    [mediaQueries.medium.down]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    [mediaQueries.xsmall.only]: {
      padding: '64px 16px 24px 16px',
    },
  },
  container: {
    width: '100%',

    [mediaQueries.medium.down]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  logoWrapper: {
    display: 'flex',
    marginBottom: '36px',

    [mediaQueries.medium.down]: {
      justifyContent: 'center',
    },
  },
  logo: {
    minHeight: '72px',

    [mediaQueries.small.down]: {
      minHeight: '60px',
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.colors.typography[0],

    [mediaQueries.medium.down]: {
      flexDirection: 'column',
      alignItems: 'center',
      alignContent: 'center',
    },
  },
  description: {
    maxWidth: '500px',
    fontSize: '18px',
    lineHeight: '26px',
    letterSpacing: '1.88px',
    color: theme.colors.typography[0],
    marginRight: '120px',

    [mediaQueries.medium.down]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginRight: 0,
      marginBottom: '60px',
    },
  },
  pagesWrapper: {
    width: '100%',
    maxWidth: '800px',
    marginRight: '120px',
    color: theme.colors.typography[0],

    [mediaQueries.medium.down]: {
      marginRight: 0,
      justifyContent: 'center',
    },
  },
  footer: {
    backgroundColor: `${theme.colors.surfaces[0]}1A`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '80px',
    padding: '8px 16px',
    borderRadius: '8px',

    [mediaQueries.small.down]: {
      flexDirection: 'column-reverse',
      gap: '24px',
      padding: '24px',
    },
  },
}));

const Footer = () => {
  // const { pathname } = useLocation();
  // const isInside3D = pathname.includes(ROUTES.EDITOR) || pathname.includes(ROUTES.VIEWER);
  const { classes } = useStyles();

  const { t } = useTranslation();

  // if (isInside3D) {
  //   return null;
  // }

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.logoWrapper}>
          <Logo className={classes.logo} href={ROUTES.HOME} shouldShrink={false} />
        </div>
        <div className={classes.content}>
          <div className={classes.description}>{t('Footer.Description.Text')}</div>
          <div className={classes.pagesWrapper}>
            <PagesInfo />
          </div>
        </div>
        <div className={classes.footer}>
          <div>
            <Copyright />
          </div>
          <div>
            <SocialMedia />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
