import { useTranslation } from '../../../../infrastructure/translation/TranslationProvider';
import VisibilityToggle from './VisibilityToggle';

const HideGroups = () => {
  const { t } = useTranslation();

  const handleChange = () => {};

  return (
    <VisibilityToggle
      title={t('SettingsPage.VisibilityTab.HideGroups.Title')}
      description={t('SettingsPage.VisibilityTab.HideGroups.Description')}
      isChecked={false}
      onChange={handleChange}
    />
  );
};

export default HideGroups;
