export var isValueValid = function isValueValid(_ref) {
  var fontSizeValue = _ref.fontSizeValue,
    setFontSize = _ref.setFontSize;
  var numberRegex = /^[0-9]+$/;
  if (!numberRegex.test(fontSizeValue) && fontSizeValue !== '') {
    return false;
  }
  if (fontSizeValue === '') {
    setFontSize('');
    return false;
  }
  return true;
};
export var handleValueWithinRange = function handleValueWithinRange(_ref2) {
  var fontSizeValue = _ref2.fontSizeValue,
    maxNumber = _ref2.maxNumber,
    minNumber = _ref2.minNumber,
    setFontSize = _ref2.setFontSize;
  if (maxNumber != null && Number(fontSizeValue) > maxNumber) {
    setFontSize(maxNumber);
    return false;
  }
  if (minNumber != null && Number(fontSizeValue) < minNumber) {
    setFontSize(minNumber);
    return false;
  }
  setFontSize(Number(fontSizeValue));
  return true;
};