const ROLES = { FREE: 'free-user', PRO: 'pro-user', PREMIUM: 'premium-user' };
export const ROLE_NAMES = {
  [ROLES.FREE]: 'Free user',
  [ROLES.PRO]: 'PRO user',
  [ROLES.PREMIUM]: 'PREMIUM user',
};

export const getMetastepsRole = (accessTokenPayload) => {
  const { roles } = accessTokenPayload || {};

  if (roles?.includes(ROLES.PREMIUM)) {
    return ROLES.PREMIUM;
  }
  if (roles?.includes(ROLES.PRO)) {
    return ROLES.PRO;
  }
  return ROLES.FREE;
};

export default ROLES;
