import { useState } from 'react';
var useViewType = function useViewType(_temp) {
  var _ref = _temp === void 0 ? {} : _temp,
    _ref$defaultIsGrid = _ref.defaultIsGrid,
    defaultIsGrid = _ref$defaultIsGrid === void 0 ? true : _ref$defaultIsGrid;
  var _useState = useState(defaultIsGrid),
    isGrid = _useState[0],
    setIsGrid = _useState[1];
  return {
    isGrid: isGrid,
    setIsGrid: setIsGrid
  };
};
export default useViewType;