const BASE_PATH = '/api/communicationInteractionService';

const communicationInteractionService = {
  directmessagegroups: `${BASE_PATH}/directmessagegroup`,
  directmessagegroup: (id) => `${BASE_PATH}/directmessagegroup/${id}`,
  directMessageGroupMessages: (id) => `${BASE_PATH}/directmessagegroup/${id}/messages`,
  key: `${BASE_PATH}/key`,
  parties: `${BASE_PATH}/party`,
  party: (partyId) => `${BASE_PATH}/party/${partyId}`,
  partyInvitations: `${BASE_PATH}/partyinvitation`,
  partyInvitation: (partyInvitationId) => `${BASE_PATH}/partyinvitation/${partyInvitationId}`,
};

export default communicationInteractionService;

export const LIVE_USER_HUB = `${BASE_PATH}/liveuserhub`;
export const DIRECT_MESSAGE_HUB_URL = `${BASE_PATH}/directmessagegrouphub`;

export const ALL_HUBS_URLS = [LIVE_USER_HUB, DIRECT_MESSAGE_HUB_URL];
