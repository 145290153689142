import loadable from '@loadable/component';
import { LoadingScreen } from '@artsteps/components';

export const loadWithTimeout = (ms) => (promise) => {
  let timeout;
  return Promise.race([
    promise,
    new Promise((_, reject) => {
      timeout = setTimeout(
        () => reject(new Error(`Failed to load chunked component due to timeout`)),
        ms,
      );
    }),
  ])
    .then((component) => {
      clearTimeout(timeout);
      return component;
    })
    .catch((err) => {
      clearTimeout(timeout);
      throw err;
    });
};

export const TIMEOUT = 60 * 1000;

export const createLoadableComponent = (loader) =>
  loadable(() => loadWithTimeout(TIMEOUT)(loader()), {
    fallback: <LoadingScreen dataTestId="loadablePage-loadingScreen" />,
  });
