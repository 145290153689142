import { createStyles } from '@mantine/core';
import SearchVisibility from './components/SearchVisibility';
import HideContentLibrary from './components/HideContentLibrary';
import HideGroups from './components/HideGroups';

const useStyles = createStyles(() => ({
  wrapper: {
    width: '100%',
    height: '100%',
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    gap: '72px',

    background: 'white',
    borderRadius: '16px',
    border: '1px solid #dcdcdc',
    padding: '106px 96px',
  },
}));

const VisibilityTab = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <SearchVisibility />
      <HideContentLibrary />
      <HideGroups />
    </div>
  );
};

export default VisibilityTab;
