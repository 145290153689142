/* eslint-disable no-param-reassign */
export const transformObjects = (obj, transform) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    newObj[transform(key)] = obj[key];
  });
  return newObj;
};

const defaultTransformValue = (key, value) => value;

export const deepTransformObjects = (
  obj,
  transformKey,
  transformValue = defaultTransformValue,
  currentKey = '',
) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => deepTransformObjects(item, transformKey, transformValue, currentKey));
  }

  if (typeof obj !== 'object' || obj === null) {
    return transformValue(currentKey, obj);
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      transformKey(key),
      deepTransformObjects(value, transformKey, transformValue, key),
    ]),
  );
};
