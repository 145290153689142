var parseHtmlContent = function parseHtmlContent(htmlString) {
  var parser = new DOMParser();
  var doc = parser.parseFromString(htmlString, 'text/html');
  var result = [];
  var currentObj = null;

  // Traverse all child nodes in the body
  doc.body.childNodes.forEach(function (node) {
    if (node.nodeType === 1 && /^H[1-6]$/.test(node.tagName)) {
      // If we encounter a heading tag (h1, h2, h3, etc.)

      if (currentObj) {
        result.push(currentObj); // Push the previous object, even if it has no content
      }
      var id = node.textContent.trim().toLowerCase().replace(/\s+/g, '-');
      var clonedEl = node.cloneNode(true);
      clonedEl.id = "" + id;
      node.replaceWith(clonedEl);

      // Create a new object for the new header
      currentObj = {
        header: {
          text: clonedEl.textContent.trim(),
          id: clonedEl.id,
          level: node.tagName.toLowerCase(),
          element: node.outerHTML
        },
        content: ''
      };
    } else {
      // If it's not a heading, append the content to the current object
      if (!currentObj) {
        // Handle content before any headers
        currentObj = {
          header: null,
          content: ''
        };
      }
      currentObj.content += node.outerHTML || node.textContent;
    }
  });
  // Push the last object if it exists (whether it has content or not)
  if (currentObj) {
    result.push(currentObj);
  }
  return result;
};
export default parseHtmlContent;