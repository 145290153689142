import { createStyles } from '@mantine/core';
import { Button, Icon, mediaQueries } from '@artsteps/components';

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    gap: '24px',
    alignItems: 'center',
    height: '54px',

    [mediaQueries.medium.down]: {
      gap: '48px',
    },

    [mediaQueries.small.down]: {
      gap: '32px',
    },
  },
  socialMediaButton: {
    height: '100%',
  },
  icon: {
    fill: theme.colors.typography[7],
  },
}));

const SocialMedia = () => {
  const { classes } = useStyles();

  const socialMedia = [
    // { name: 'facebook', url: 'https://www.facebook.com/metasteps' },
    { name: 'instagram', url: 'https://www.instagram.com/metasteps.metaverse/' },
    { name: 'linkedin', url: 'https://www.linkedin.com/company/metasteps' },
    { name: 'twitter', url: 'https://x.com/meta_steps' },
    { name: 'youtube', url: 'https://www.youtube.com/@Metasteps' },
  ];

  return (
    <div className={classes.wrapper}>
      {socialMedia.map((social) => (
        <Button
          classNames={{ root: classes.socialMediaButton }}
          href={social.url}
          key={social.name}
          target="_blank"
          variant="unstyled"
        >
          <Icon className={classes.icon} id={social.name?.toLowerCase()} size="40px" />
        </Button>
      ))}
    </div>
  );
};

export default SocialMedia;
