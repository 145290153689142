import { useEffect } from 'react';
import { useUnityInspectorContext } from '../provider/UnityInspectorProvider';

const useUnityInspector = ({ isActive = false, elementRef, artifactDescription, artifactType }) => {
  const { moveInspectorInElement, unityInspectorRef, setArtifactDescription, setArtifactType } =
    useUnityInspectorContext();

  useEffect(() => {
    const unityInspectorElement = unityInspectorRef.current;
    const element = elementRef.current;
    if (isActive && element && unityInspectorElement) {
      moveInspectorInElement(element);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, elementRef, unityInspectorRef]);

  useEffect(() => {
    if (typeof setArtifactDescription === 'function') {
      setArtifactDescription(artifactDescription);
    }
  }, [setArtifactDescription, artifactDescription]);

  useEffect(() => {
    if (typeof setArtifactType === 'function') {
      setArtifactType(artifactType);
    }
  }, [setArtifactType, artifactType]);
};

export default useUnityInspector;
