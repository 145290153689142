import PropTypes from 'prop-types';
import userDataPropTypes from './userData';
import socialMediaPropTypes from './socialMedia';
var districtPropTypes = PropTypes.shape({
  active: PropTypes.bool,
  coverPhoto: PropTypes.string,
  description: PropTypes.string,
  districtId: PropTypes.string,
  districtMembers: PropTypes.arrayOf(userDataPropTypes),
  districtUserRole: PropTypes.oneOf(['Creator', 'Owner', 'Guest']),
  followers: PropTypes.arrayOf(userDataPropTypes),
  owner: userDataPropTypes,
  photo: PropTypes.string,
  "public": PropTypes.bool,
  socialMedia: PropTypes.arrayOf(socialMediaPropTypes),
  styles: PropTypes.string,
  subdomain: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  userId: PropTypes.string
});
export default districtPropTypes;