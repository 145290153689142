import { createStyles } from '@mantine/core';
import { mediaQueries } from '@artsteps/components';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../infrastructure/user-context';
import useFooterItems from './utils/useFooterItems';

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: '100%',
    maxHeight: '270px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    rowGap: '24px',
    columnGap: '160px',

    '& a': {
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '1.08px',
      color: theme.colors.typography[0],
      textDecoration: 'none',
      whiteSpace: 'nowrap',
    },

    [mediaQueries.medium.down]: {
      columnGap: '80px',
      textAlign: 'center',
    },

    [mediaQueries.small.down]: {
      maxHeight: '200px',
      alignContent: 'center',
      justifyContent: 'center',
      rowGap: '16px',
      columnGap: '48px',

      '& a': {
        fontSize: '17px',
        lineHeight: '18px',
        letterSpacing: '0.5px',
      },
    },

    [mediaQueries.xsmall.only]: {
      columnGap: '16px',
    },
  },
}));

const PagesInfo = () => {
  const { classes } = useStyles();
  const { userId } = useUserContext();

  const { pageItems } = useFooterItems(userId || '');

  return (
    <div className={classes.wrapper}>
      {pageItems.map(({ label, to }) => (
        <Link key={label} to={to}>
          {label}
        </Link>
      ))}
    </div>
  );
};

export default PagesInfo;
