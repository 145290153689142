import { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';
import { ModelDropzone as ArtFormModelDropzone } from '@artsteps/form';
import { useFormContext } from 'react-hook-form';
import {
  DefaultSizeFetched,
  AnimationClipsFetched,
} from '../../custom-hooks/usePubSub/events/uploaderEvents';
import usePublish from '../../custom-hooks/usePubSub/usePublish';
import useUnityInspector from '../../infrastructure/unity-inspector/hooks/useUnityInspector';
import { useUnityInspectorContext } from '../../infrastructure/unity-inspector/provider/UnityInspectorProvider';
import { ASSET_FIELDS } from '../uploader/utils/assetDataConstants';
import { ASSET_TYPE } from '../../constants/constants';
import { handleDropzoneDroppedFiles, validator } from './utils/filesManipulation';
import { convertFileFromBase64 } from './utils/convertFileFromBase64';
import { exportedModelFileName, exportedThumbnailFileName } from './utils/exportedModelFileNames';

const useStyles = createStyles(() => ({
  previewContainer: {
    padding: '24px',
    gap: '16px',
  },
  previewWrapper: {
    height: '280px',
    width: '400px',
  },
  inspector: {
    width: '100%',
    height: 'calc(100% - 20px)',
  },
}));

const ModelDropzone = ({
  alignError,
  classNames = {},
  errorMessage,
  maxFileSize = 4 * 1024 ** 2,
  mimeTypes = {
    'application/octet-stream': ['.bin', '.glb', '.obj', '.gltf'],
    'application/x-tgif': ['.obj'],
    'model/mtl': ['.mtl'],
    'model/obj': ['.obj'],
    'model/gltf+json': ['.gltf', '.glb'],
    'model/gltf-binary': ['.gltf', '.glb'],
    'application/zip': ['.zip'],
    'image/*': [],
  },
  urlInputName,
  fileInputName,
  imposterInputName,
  isRequired,
  onModelExported,
  onThumbnailExported,
  placeholder,
  shouldActivateOnClick,
  shouldDisplay = true,
  shouldShowError,
  supportedFileFormats,
}) => {
  const [artifactDescription, setArtifactDescription] = useState();
  const unityDivRef = useRef(null);
  const { addInspectorEventListener, removeInspectorEventListener } = useUnityInspectorContext();
  const { setValue, getValues, watch } = useFormContext();
  const { assetFile, file } = watch();
  const { publish: populateDefaultSize } = usePublish(DefaultSizeFetched.type);
  const { publish: publishAnimationClips } = usePublish(AnimationClipsFetched.type);
  const { classes, cx } = useStyles();

  const dropzoneStyles = {
    container: cx(classes.container, classNames.container),
    dropzoneRoot: cx(classes.dropzoneRoot, classNames.dropzoneRoot),
    dropzoneInner: cx(classes.dropzoneInner, classNames.dropzoneInner),
    error: cx(classes.error, classNames.error),
    previewContainer: cx(classes.previewContainer, classNames.previewContainer),
    pill: cx(classes.pill, classNames.pill),
    pillsContainer: cx(classes.pillsContainer, classNames.pillsContainer),
  };

  useEffect(() => {
    if (assetFile) {
      const isAlreadyBlob = artifactDescription?.includes('blob:');
      if (isAlreadyBlob && artifactDescription) {
        return undefined;
      }

      if (['.obj', '.gltf', '.glb'].some((substring) => assetFile.name?.includes(substring))) {
        const url = URL.createObjectURL(Array.isArray(assetFile) ? assetFile[0] : assetFile);
        const fileName = Array.isArray(assetFile) ? assetFile[0].name : assetFile.name;

        const formatFile = `${url}#file=${fileName}`;
        setArtifactDescription(formatFile);
        setValue(ASSET_FIELDS.artifactDescription, formatFile);
      }
    } else if (file) {
      setArtifactDescription(file);
      setValue(ASSET_FIELDS.artifactDescription, file);
    } else {
      setArtifactDescription(undefined);
      setValue(ASSET_FIELDS.artifactDescription, undefined);
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetFile, file, setValue]);

  const handleChange = async (uploadedFiles) => {
    const edittedFile = await handleDropzoneDroppedFiles(uploadedFiles);
    setValue(fileInputName, edittedFile);
  };

  const handleRemove = () => {
    setValue(fileInputName, '');
  };

  const handleModelExported = useCallback(
    (imposterBase64, modelBase64) => {
      if (typeof getValues === 'function' && typeof setValue === 'function') {
        const droppedFile = getValues(fileInputName);
        if (!droppedFile) {
          return;
        }
        const droppedFileName = exportedModelFileName(droppedFile);

        const modelFile = convertFileFromBase64(modelBase64, droppedFileName);
        const imposterFile = convertFileFromBase64(imposterBase64, `imposter_${droppedFileName}`);
        setValue(fileInputName, modelFile);
        if (imposterInputName) {
          setValue(imposterInputName, imposterFile);
        }

        if (typeof onModelExported === 'function') {
          onModelExported(imposterFile, modelFile);
        }
      }
    },
    [getValues, fileInputName, setValue, imposterInputName, onModelExported],
  );

  const handleThumbnailExported = useCallback(
    (imgBase64) => {
      if (typeof getValues === 'function') {
        const droppedFile = getValues(fileInputName);
        if (!droppedFile) {
          return;
        }
        const thumbnailFileName = exportedThumbnailFileName(droppedFile);

        const thumbnailFile = convertFileFromBase64(imgBase64, thumbnailFileName);

        if (typeof onThumbnailExported === 'function') {
          onThumbnailExported(thumbnailFile);
        }
      }
    },
    [fileInputName, getValues, onThumbnailExported],
  );

  const handleModelReady = useCallback(
    (modelReadyResponse) => {
      const modelReady = JSON.parse(modelReadyResponse);
      if (modelReady.Success) {
        populateDefaultSize(DefaultSizeFetched.type, modelReady.DefaultSize);
        publishAnimationClips(AnimationClipsFetched.type, modelReady.ModelAnimations);
      }
    },
    [populateDefaultSize, publishAnimationClips],
  );

  useEffect(() => {
    if (addInspectorEventListener) {
      addInspectorEventListener('ModelExported', handleModelExported);
      addInspectorEventListener('ThumbnailExported', handleThumbnailExported);
      addInspectorEventListener('ModelReady', handleModelReady);
    }

    return () => {
      if (removeInspectorEventListener) {
        removeInspectorEventListener('ModelExported', handleModelExported);
        removeInspectorEventListener('ThumbnailExported', handleThumbnailExported);
        removeInspectorEventListener('ModelReady', handleModelReady);
      }
    };
  }, [
    handleModelExported,
    handleThumbnailExported,
    addInspectorEventListener,
    removeInspectorEventListener,
    handleModelReady,
  ]);

  useUnityInspector({
    isActive: shouldDisplay && artifactDescription?.length > 0,
    elementRef: unityDivRef,
    artifactDescription,
    artifactType: ASSET_TYPE.model,
  });

  return (
    <ArtFormModelDropzone
      alignError={alignError}
      classNames={dropzoneStyles}
      urlInputName={urlInputName}
      fileInputName={fileInputName}
      errorMessage={errorMessage}
      isRequired={isRequired}
      mimeTypes={mimeTypes}
      maxFileSize={maxFileSize}
      placeholder={placeholder}
      shouldActivateOnClick={shouldActivateOnClick}
      shouldDisplayFilePills
      onDrop={handleChange}
      onRemove={handleRemove}
      validator={validator}
      shouldShowError={shouldShowError}
      supportedFileFormats={supportedFileFormats}
    >
      <div className={classes.inspector} ref={unityDivRef} />
    </ArtFormModelDropzone>
  );
};

ModelDropzone.propTypes = {
  alignError: PropTypes.oneOf(['start', 'center', 'end']),
  classNames: PropTypes.shape({
    autoCropperWrapper: PropTypes.string,
    container: PropTypes.string,
    dropzoneInner: PropTypes.string,
    dropzoneRoot: PropTypes.string,
    error: PropTypes.string,
    errorMessage: PropTypes.string,
    inspectorWrapper: PropTypes.string,
    pill: PropTypes.string,
    pillsContainers: PropTypes.string,
    placeholder: PropTypes.string,
    previewContainer: PropTypes.string,
  }),
  errorMessage: PropTypes.node,
  fileInputName: PropTypes.string,
  imposterInputName: PropTypes.string,
  isRequired: PropTypes.bool,
  maxFileSize: PropTypes.number,
  mimeTypes: PropTypes.any,
  onModelExported: PropTypes.func,
  onThumbnailExported: PropTypes.func,
  placeholder: PropTypes.node,
  shouldActivateOnClick: PropTypes.bool,
  shouldDisplay: PropTypes.bool,
  shouldShowError: PropTypes.bool,
  supportedFileFormats: PropTypes.arrayOf(PropTypes.string),
  urlInputName: PropTypes.string,
};

export default ModelDropzone;
