var formatCompactNumber = function formatCompactNumber(number, options) {
  if (options === void 0) {
    options = {
      decimals: 1,
      digitsNumber: undefined
    };
  }
  var _options = options,
    digitsNumber = _options.digitsNumber,
    decimals = _options.decimals;
  if (typeof number !== 'number') {
    return null;
  }
  if (Math.abs(number) < 1000) {
    var numberStringified = String(number);
    if (typeof digitsNumber === 'number') {
      return numberStringified.padStart(digitsNumber, '0');
    }
    return numberStringified;
  }
  var si = [{
    v: 1,
    s: ''
  }, {
    v: 1e3,
    s: 'K'
  }, {
    v: 1e6,
    s: 'M'
  }, {
    v: 1e9,
    s: 'B'
  }, {
    v: 1e12,
    s: 'T'
  }, {
    v: 1e15,
    s: 'P'
  }, {
    v: 1e18,
    s: 'E'
  }, {
    v: 1e21,
    s: 'Z'
  }, {
    v: 1e24,
    s: 'Y'
  }];
  var i = Math.floor(Math.log(Math.abs(number)) / Math.log(1000));
  return (number / si[i].v).toFixed(decimals).replace(/\.0+$/, '') + si[i].s;
};
export default formatCompactNumber;