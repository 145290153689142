import { useState } from 'react';
var useSortProperties = function useSortProperties(_temp) {
  var _ref = _temp === void 0 ? {} : _temp,
    _ref$properties = _ref.properties,
    properties = _ref$properties === void 0 ? [] : _ref$properties,
    defaultSelectedSortProperty = _ref.defaultSelectedSortProperty;
  var _useState = useState(properties),
    sortProperties = _useState[0],
    setSortProperties = _useState[1];
  var _useState2 = useState(defaultSelectedSortProperty),
    selectedSortProperty = _useState2[0],
    setSelectedSortProperty = _useState2[1];
  return {
    sortProperties: sortProperties,
    setSortProperties: setSortProperties,
    selectedSortProperty: selectedSortProperty,
    setSelectedSortProperty: setSelectedSortProperty
  };
};
export default useSortProperties;