import screenSizes from './screenSizes';
var upQuery = function upQuery(min) {
  return "@media all and (min-width: " + min + ")";
};
var onlyQuery = function onlyQuery(min, max) {
  return "@media all and (min-width: " + min + ") and (max-width: " + max + ")";
};
var downQuery = function downQuery(max) {
  return "@media all and (max-width: " + max + ")";
};
var xsmall = {
  only: downQuery(screenSizes.xsmall.max)
};
var small = {
  up: upQuery(screenSizes.small.min),
  only: onlyQuery(screenSizes.small.min, screenSizes.small.max),
  down: downQuery(screenSizes.small.max)
};
var medium = {
  up: upQuery(screenSizes.medium.min),
  only: onlyQuery(screenSizes.medium.min, screenSizes.medium.max),
  down: downQuery(screenSizes.medium.max)
};
var large = {
  up: upQuery(screenSizes.large.min),
  only: onlyQuery(screenSizes.large.min, screenSizes.large.max),
  down: downQuery(screenSizes.large.max)
};
var xlarge = {
  down: downQuery(screenSizes.xlarge.max),
  only: onlyQuery(screenSizes.xlarge.min, screenSizes.xlarge.max)
};
var mobile = {
  landscape: small.down + " and (orientation: landscape)",
  portrait: small.down + " and (orientation: portrait)"
};
export default {
  xsmall: xsmall,
  small: small,
  medium: medium,
  large: large,
  xlarge: xlarge,
  mobile: mobile
};