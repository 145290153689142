var flexDirectionDict = {
  top: 'column',
  right: 'row-reverse',
  bottom: 'column-reverse',
  left: 'row'
};
var extractFlexPlacement = function extractFlexPlacement(placementString) {
  if (typeof placementString === 'undefined') {
    return {
      flexDirection: 'row',
      alignItems: 'flex-start'
    };
  }
  var _placementString$spli = placementString.split('-'),
    placement = _placementString$spli[0],
    alignment = _placementString$spli[1];
  var flexDirection = flexDirectionDict[placement];
  var alignItems;
  if (typeof alignment === 'undefined') {
    alignItems = 'center';
  } else {
    alignItems = alignment === 'start' ? 'flex-start' : 'flex-end';
  }
  return {
    flexDirection: flexDirection,
    alignItems: alignItems
  };
};
export default extractFlexPlacement;