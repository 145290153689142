import PropTypes from 'prop-types';
var spacePropTypes = PropTypes.shape({
  adultContent: PropTypes.bool,
  audioUri: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.number),
  categoryObjects: PropTypes.array,
  comments: PropTypes.number,
  coverPhotoUri: PropTypes.string,
  createdAt: PropTypes.string,
  description: PropTypes.string,
  likes: PropTypes.number,
  members: PropTypes.array,
  owner: PropTypes.string,
  ownerDetails: PropTypes.shape({
    name: PropTypes.string,
    profilePicture: PropTypes.string,
    userId: PropTypes.string
  }),
  photoGallery: PropTypes.array,
  publishedAtDistricts: PropTypes.array,
  publishedAtDistrictsAt: PropTypes.string,
  publishedPublic: PropTypes.bool,
  publishedPublicAt: PropTypes.string,
  restrictedToGroups: PropTypes.array,
  sceneId: PropTypes.string,
  spaceType: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.string),
  thumbnailUri: PropTypes.string,
  title: PropTypes.string,
  updatedAt: PropTypes.string,
  versionOfMarketplaceSceneInstanceId: PropTypes.number,
  versionOfSceneId: PropTypes.number,
  views: PropTypes.number
});
export default spacePropTypes;