import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';
import { accessibleOnInteraction } from '@artsteps/components';
import { NavLink } from 'react-router-dom';

const useStyles = createStyles({
  link: {
    textDecoration: 'none',
  },
});

const CustomLink = ({ className, children, onClick, route }) => {
  const { classes, cx } = useStyles();

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  const isAbsoluteUrl = /^https?:\/\//.test(route);

  const classNames = cx(classes.link, className);

  return isAbsoluteUrl ? (
    <a className={classNames} {...accessibleOnInteraction(handleClick)} href={route}>
      {children}
    </a>
  ) : (
    <NavLink
      data-testid={`navlink-${route}`}
      className={classNames}
      onClick={handleClick}
      to={route}
    >
      {children}
    </NavLink>
  );
};

CustomLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  route: PropTypes.string,
};

export default CustomLink;
