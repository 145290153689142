import { createStyles } from '@mantine/core';
import { useOidc } from '@axa-fr/react-oidc';
import { Button, mediaQueries, screenSizes } from '@artsteps/components';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from '../../../infrastructure/translation/TranslationProvider';
import ROUTES from '../../../server/endpoints/routes';
import User from './User';

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
    alignItems: 'center',

    [mediaQueries.small.down]: {
      position: 'absolute',
      right: '16px',
    },

    [mediaQueries.xsmall.only]: {
      visibility: 'hidden',
    },
  },
  loginButton: {
    width: '100%',
    maxWidth: '160px',
    minWidth: '96px',
    height: '42px',
    backgroundColor: 'transparent',
    color: theme.colors.typography[0],
    border: `2px solid ${theme.colors.typography[0]}`,

    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 0.9,
    },
  },
  buttons: {
    height: '42px',
    letterSpacing: '1.6px',
  },
}));

const UserSection = () => {
  // const { isAuthenticated, login } = useOidc();
  const { isAuthenticated } = useOidc();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const shouldHideCreateSpace = useMediaQuery(`(max-width: ${screenSizes.medium.max})`);

  return (
    <div className={classes.wrapper}>
      {!shouldHideCreateSpace && (
        <Button
          classNames={{ root: classes.buttons }}
          href={ROUTES.COMINGSOON}
          label={t('Menu.Button.CreateSpace')}
          variant="gradient"
          isUpperCase={false}
        />
      )}
      {isAuthenticated ? (
        <>
          {/* <NotificationsButton /> */}
          <User />
        </>
      ) : (
        <Button
          classNames={{ root: classes.loginButton }}
          isUpperCase={false}
          label={t('Menu.Button.LogIn')}
          // onClick={() => login()}
          href={ROUTES.COMINGSOON}
        />
      )}
    </div>
  );
};

export default UserSection;
