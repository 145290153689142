import PropTypes from 'prop-types';
import guidedTourStoryPointPropTypes from './guidedTourStoryPoint';
var guidedTourPropTypes = PropTypes.shape({
  description: PropTypes.string,
  duration: PropTypes.number,
  guidedTourId: PropTypes.string,
  points: PropTypes.arrayOf(guidedTourStoryPointPropTypes),
  "public": PropTypes.bool,
  sceneId: PropTypes.string,
  title: PropTypes.string
});
export default guidedTourPropTypes;