import PropTypes from 'prop-types';
var materialPropTypes = {
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  title: PropTypes.string,
  file: PropTypes.string,
  defaultScale: PropTypes.string,
  license: {
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    licenseId: PropTypes.string,
    title: PropTypes.string,
    acronym: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    link: PropTypes.string,
    "default": PropTypes.bool
  },
  addressable: {
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    addressableId: PropTypes.string,
    internalAddress: PropTypes.string,
    catalogs: [{
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      catalogId: 0,
      platform: PropTypes.number,
      pathId: PropTypes.string,
      bundlePathIds: PropTypes.arrayOf(PropTypes.string)
    }]
  },
  materialId: PropTypes.string,
  vectorProperties: PropTypes.string,
  floatProperties: PropTypes.string,
  textureProperties: PropTypes.string,
  unlit: PropTypes.bool
};
export default materialPropTypes;