const BASE_PATH = '/api/assetService';

const subPath = {
  addressable: `${BASE_PATH}/addressable`,
  audio: `${BASE_PATH}/audio`,
  catalog: `${BASE_PATH}/catalog`,
  font: `${BASE_PATH}/font`,
  license: `${BASE_PATH}/license`,
  material: `${BASE_PATH}/material`,
  model3d: `${BASE_PATH}/model3d`,
  picture: `${BASE_PATH}/picture`,
  portal: `${BASE_PATH}/portal`,
  prop: `${BASE_PATH}/prop`,
  shader: `${BASE_PATH}/shader`,
  sky: `${BASE_PATH}/sky`,
  spaceTemplate: `${BASE_PATH}/spacetemplate`,
  spaceTemplateFamily: `${BASE_PATH}/spacetemplatefamily`,
  structureComponent: `${BASE_PATH}/structureComponent`,
  text3d: `${BASE_PATH}/text3d`,
  texture: `${BASE_PATH}/texture`,
  theme: `${BASE_PATH}/customspacetheme`,
  video: `${BASE_PATH}/video`,
};

const assetManagement = {
  addressables: `${subPath.addressable}`,
  addressable: (id) => `${subPath.addressable}/${id}`,
  audios: `${subPath.audio}`,
  audio: (id) => `${subPath.audio}/${id}`,
  audiosLibrary: `${subPath.audio}/myLibrary`,
  catalogs: `${subPath.catalog}`,
  catalog: (id) => `${subPath.addressable}/${id}`,
  fonts: `${subPath.font}`,
  font: (id) => `${subPath.font}/${id}`,
  fontUnicodeRanges: `${subPath.font}/unicodeRanges`,
  licenses: `${subPath.license}`,
  license: (id) => `${subPath.license}/${id}`,
  materials: `${subPath.material}`,
  material: (id) => `${subPath.material}/${id}`,
  materialCategories: `${subPath.material}/categories`,
  models3d: `${subPath.model3d}`,
  model3d: (id) => `${subPath.model3d}/${id}`,
  models3dLibrary: `${subPath.model3d}/myLibrary`,
  pictures: `${subPath.picture}`,
  picture: (id) => `${subPath.picture}/${id}`,
  picturesLibrary: `${subPath.picture}/myLibrary`,
  portals: `${subPath.portal}`,
  portal: (id) => `${subPath.portal}/${id}`,
  portalLibrary: `${subPath.portal}/myLibrary`,
  props: `${subPath.prop}`,
  prop: (id) => `${subPath.prop}/${id}`,
  propCategories: `${subPath.prop}/categories`,
  shaders: `${subPath.shader}`,
  shader: (id) => `${subPath.shader}/${id}`,
  skys: `${subPath.sky}`,
  sky: (id) => `${subPath.sky}/${id}`,
  spaceTemplates: `${subPath.spaceTemplate}`,
  spaceTemplateFamilies: `${subPath.spaceTemplateFamily}`,
  spaceTemplate: (id) => `${subPath.spaceTemplate}/${id}`,
  spaceTemplateFamily: (id) => `${subPath.spaceTemplateFamily}/${id}`,
  structureComponents: `${subPath.structureComponent}`,
  structureComponent: (id) => `${subPath.structureComponent}/${id}`,
  texts3d: `${subPath.text3d}`,
  text3d: (id) => `${subPath.text3d}/${id}`,
  texts3dLibrary: `${subPath.text3d}/myLibrary`,
  textures: `${subPath.texture}`,
  texture: (id) => `${subPath.texture}/${id}`,
  textureLibrary: `${subPath.texture}/myLibrary`,
  themes: `${subPath.theme}`,
  theme: (id) => `${subPath.theme}/${id}`,
  videos: `${subPath.video}`,
  video: (id) => `${subPath.video}/${id}`,
  videosLibrary: `${subPath.video}/myLibrary`,
};

export default assetManagement;
