import screenSizes from '../../utils/screenSizes';
var defaultTheme = {
  breakpoints: {
    xs: screenSizes.xsmall.max,
    sm: screenSizes.small.max,
    md: screenSizes.medium.max,
    lg: screenSizes.large.max,
    xl: screenSizes.xlarge.max
  },
  colorScheme: 'light',
  colors: {
    metasteps: ['var(--primary-color-0)', 'var(--primary-color-1)', 'var(--primary-color-2)', 'var(--primary-color-3)', 'var(--primary-color-4)', 'var(--primary-color-5)', 'var(--primary-color-6)', 'var(--primary-color-7)', 'var(--primary-color-8)', 'var(--primary-color-9)'],
    typography: ['var(--text-color-0)', 'var(--text-color-1)', 'var(--text-color-2)', 'var(--text-color-3)', 'var(--text-color-4)', 'var(--text-color-5)', 'var(--text-color-6)', 'var(--text-color-7)', 'var(--text-color-8)', 'var(--text-color-9)'],
    surfaces: ['#FFFFFF', '#FFFFFFDE',
    // 87%
    '#DEDEDFE6',
    // 90%
    '#FFFFFFBD',
    // 74%
    '#FFFFFF61',
    // 38%
    '#00000061',
    // 38%
    '#0000009E',
    // 62%
    '#000000DE',
    // 87%
    '#00000033',
    // 20%
    '#9C4353']
  },
  defaultRadius: 'xl',
  radius: {
    xs: 'var(--default-radius-xs)',
    sm: 'var(--default-radius-sm)',
    md: 'var(--default-radius-md)',
    lg: 'var(--default-radius-lg)',
    xl: 'var(--default-radius-xl)'
  },
  fontFamily: 'var(--font-family)',
  loader: 'oval',
  other: {
    typographyColor: function typographyColor(theme) {
      return theme.colorScheme === 'dark' ? theme.colors.typography[2] : theme.colors.typography[4];
    },
    themeColors: {
      primaryColors: ['#DFF3EF', '#B0E0D6', '#7CCDBB', '#43B9A0', '#01A98D', '#00997A', '#008C6E', '#007C5F', '#006C51', '#005035'],
      textColors: ['#FFFFFF', '#FFFFFFDE', '#FFFFFF9E', '#DCDCDC', '#00000061', '#0000009E', '#000000DE', '#000000', '#43B9A0', '#9C4353']
    },
    logoHeight: '40px',
    getLogoHeight: function getLogoHeight() {
      return 'var(--logo-height)';
    },
    defaultRadius: {
      xs: '2px',
      sm: '4px',
      md: '8px',
      lg: '16px',
      xl: '32px'
    },
    fontFamily: "'Ubuntu', sans-serif"
  },
  fontSizes: {
    xs: 10,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 22
  },
  globalStyles: function globalStyles(theme) {
    return {
      '*': {
        outline: 'none',
        userSelect: 'none' /* Standard syntax */,
        scrollbarWidth: 'thin',
        scrollbarColor: theme.colors.surfaces[6] + " " + theme.colors.surfaces[2],
        overscrollBehavior: 'contain',
        scrollBehavior: 'smooth',
        fontFamily: 'var(--font-family) !important'
      },
      ':root': {
        '--primary-color-0': theme.other.themeColors.primaryColors[0],
        '--primary-color-1': theme.other.themeColors.primaryColors[1],
        '--primary-color-2': theme.other.themeColors.primaryColors[2],
        '--primary-color-3': theme.other.themeColors.primaryColors[3],
        '--primary-color-4': theme.other.themeColors.primaryColors[4],
        '--primary-color-5': theme.other.themeColors.primaryColors[5],
        '--primary-color-6': theme.other.themeColors.primaryColors[6],
        '--primary-color-7': theme.other.themeColors.primaryColors[7],
        '--primary-color-8': theme.other.themeColors.primaryColors[8],
        '--primary-color-9': theme.other.themeColors.primaryColors[9],
        '--text-color-0': theme.other.themeColors.textColors[0],
        '--text-color-1': theme.other.themeColors.textColors[1],
        '--text-color-2': theme.other.themeColors.textColors[2],
        '--text-color-3': theme.other.themeColors.textColors[3],
        '--text-color-4': theme.other.themeColors.textColors[4],
        '--text-color-5': theme.other.themeColors.textColors[5],
        '--text-color-6': theme.other.themeColors.textColors[6],
        '--text-color-7': theme.other.themeColors.textColors[7],
        '--text-color-8': theme.other.themeColors.textColors[8],
        '--text-color-9': theme.other.themeColors.textColors[9],
        '--logo-height': theme.other.logoHeight,
        '--default-radius-xs': theme.other.defaultRadius.xs,
        '--default-radius-sm': theme.other.defaultRadius.sm,
        '--default-radius-md': theme.other.defaultRadius.md,
        '--default-radius-lg': theme.other.defaultRadius.lg,
        '--default-radius-xl': theme.other.defaultRadius.xl,
        '--font-family': theme.other.fontFamily
      },
      body: {
        overflowX: 'hidden',
        fontFamily: 'var(--font-family)'
      },
      '::-webkit-scrollbar': {
        width: '4px',
        height: '4px'
      },
      '::-webkit-scrollbar-thumb': {
        backgroundClip: 'padding-box',
        backgroundColor: theme.colors.surfaces[6],
        borderRadius: '16px'
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: '#0000004D'
      },
      '@supports (-moz-appearance:none)': {
        '*': {
          scrollbarWidth: 'auto'
        }
      }
    };
  },
  headings: {
    sizes: {
      h1: {
        fontSize: 80
      },
      h2: {
        fontSize: 66
      },
      h3: {
        fontSize: 56
      },
      h4: {
        fontSize: 44
      },
      h5: {
        fontSize: 32
      }
    }
  },
  primaryColor: 'metasteps',
  primaryShade: {
    light: '3',
    dark: '2'
  },
  shadows: {
    active: function active(theme) {
      return "0 0 7px 3px " + theme.colors[theme.primaryColor][2];
    },
    gradientButton: function gradientButton(theme) {
      return "0px 0px 10px " + theme.colors[theme.primaryColor][7];
    },
    basic: '0px 2px 5px #00000066',
    md: '1px 1px 3px rgba(0, 0, 0, .25)',
    xl: '5px 5px 3px rgba(0, 0, 0, .25)',
    text: '0px 0px 5px #00000066'
  },
  components: {
    Input: {
      styles: function styles(theme) {
        return {
          input: {
            '&[data-invalid]': {
              borderColor: theme.colors.typography[9],
              color: theme.colors.typography[9],
              '&::placeholder': {
                color: theme.colors.typography[9]
              }
            }
          }
        };
      }
    }
  }
};
export default defaultTheme;