import PropTypes from 'prop-types';
import { districtPropTypes } from '@artsteps/types';
import { useContext, createContext, useMemo, useState } from 'react';

const DistrictContext = createContext();

const DistrictProvider = ({ children, districtData: districtInfo, subdomain, domain }) => {
  const [districtData, setDistrictData] = useState(districtInfo);

  const value = useMemo(
    () => ({
      districtData,
      setDistrictData,
      subdomain,
      domain,
      isDistrictOwner: districtInfo?.districtUserRole === 'Owner',
      isDistrictCreator: districtInfo?.districtUserRole === 'Creator',
    }),
    [districtData, districtInfo, domain, subdomain],
  );

  return <DistrictContext.Provider value={value}>{children}</DistrictContext.Provider>;
};

const useDistrict = () => {
  const context = useContext(DistrictContext);

  if (!context) {
    throw new Error('useDistrict must be used within a DistrictProvider');
  }

  return context;
};

DistrictProvider.propTypes = {
  children: PropTypes.node,
  districtData: districtPropTypes,
  domain: PropTypes.string,
  subdomain: PropTypes.string,
};

export { DistrictProvider, useDistrict };
