import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';
import { mediaQueries } from '@artsteps/components';
import { Link } from 'react-router-dom';
import ROUTES from '../../server/endpoints/routes';
import { useTranslation } from '../../infrastructure/translation/TranslationProvider';

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '64px',

    [mediaQueries.medium.down]: {
      gap: '36px',
    },

    [mediaQueries.small.down]: {
      position: 'initial',
      flexDirection: 'column',
      gap: '32px',
    },
  },
  links: {
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.1.2px',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    color: theme.colors.typography[0],
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: '-2px',
      left: 0,
      width: '100%',
      height: '3px',
      backgroundColor: theme.fn.primaryColor(),
      opacity: 0,
      transition: ' transform 0.3s ease, opacity 0.3s ease',
    },
    '&:hover::before, &:active::before': {
      opacity: 1,
    },
    '&:active::before': {
      backgroundColor: theme.colors[theme.primaryColor][3],
    },
  },
  button: {
    all: 'unset',
    cursor: 'pointer',
  },
}));

const MainHeaderSection = ({
  classNames = {},
  hideOptions = {
    shouldHideHomepage: false,
    shouldHideExplore: false,
    shouldHidePricing: false,
    shouldHideResources: false,
    shouldHideContactUs: false,
  },
  onClose,
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const {
    shouldHideHomepage,
    shouldHideExplore,
    shouldHidePricing,
    // shouldHideResources,
    shouldHideContactUs,
  } = hideOptions;

  return (
    <div className={classes.wrapper}>
      {!shouldHideHomepage && (
        <Link className={cx(classes.links, classNames.homepage)} to={ROUTES.HOME}>
          <button className={classes.button} onClick={onClose} type="button">
            {t('Global.Home.Label')}
          </button>
        </Link>
      )}
      {!shouldHideExplore && (
        <Link className={cx(classes.links, classNames.explore)} to={ROUTES.EXPLORE}>
          <button className={classes.button} onClick={onClose} type="button">
            {t('Menu.Links.Explore')}
          </button>
        </Link>
      )}
      {/* {!shouldHideResources && (
        <Link className={cx(classes.links, classNames.resources)} to={ROUTES.RESOURCES.BASE}>
          <button className={classes.button} onClick={onClose} type="button">
            {t('Global.Resources.Label')}
          </button>
        </Link>
      )} */}
      {!shouldHidePricing && (
        <Link className={cx(classes.links, classNames.pricing)} to={ROUTES.PRICING}>
          <button className={classes.button} onClick={onClose} type="button">
            {t('Global.Pricing.Label')}
          </button>
        </Link>
      )}
      {!shouldHideContactUs && (
        <Link className={cx(classes.links, classNames.contact)} to={ROUTES.CONTACT}>
          <button className={classes.button} onClick={onClose} type="button">
            {t('ContactPage.ContactSection.ContactForm.ContactUs.Title')}
          </button>
        </Link>
      )}
    </div>
  );
};

MainHeaderSection.propTypes = {
  classNames: PropTypes.shape({
    contact: PropTypes.string,
    explore: PropTypes.string,
    homepage: PropTypes.string,
    pricing: PropTypes.string,
    resources: PropTypes.string,
  }),
  hideOptions: PropTypes.shape({
    shouldHideContactUs: PropTypes.bool,
    shouldHideExplore: PropTypes.bool,
    shouldHideHomepage: PropTypes.bool,
    shouldHidePricing: PropTypes.bool,
    shouldHideResources: PropTypes.bool,
  }),
  onClose: PropTypes.func,
};

export default MainHeaderSection;
