import PropTypes from 'prop-types';
var commentPropTypes = PropTypes.shape({
  body: PropTypes.string,
  childComments: PropTypes.arrayOf(PropTypes.any),
  commentId: PropTypes.number,
  createdAt: PropTypes.string,
  parentCommentId: PropTypes.number,
  sceneId: PropTypes.string,
  updatedAt: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string,
    profilePicture: PropTypes.string,
    userId: PropTypes.string,
    username: PropTypes.string
  })
});
export default commentPropTypes;