const BASE_PATH = '/api/sceneService';

export const SCENE_PATH = `${BASE_PATH}/scene`;
export const PUBLISHED_SCENE_METADATA = `${BASE_PATH}/publishedscene/metadata`;
export const PUBLISHED_SCENE_CONTENT = `${BASE_PATH}/publishedscene/content`;
export const SCENE_CONTENT = `${BASE_PATH}/scene/content`;
export const SCENE_ACTIONS = `${BASE_PATH}/sceneactions`;
export const SCENE_METADATA = `${BASE_PATH}/scene/metadata`;

const sceneManagement = {
  publishedSceneContent: (id) => `${PUBLISHED_SCENE_CONTENT}/${id}`,
  publishedScenesMetadata: `${PUBLISHED_SCENE_METADATA}/search`,
  publishedSceneMetadata: (id) => `${PUBLISHED_SCENE_METADATA}/${id}`,
  publishedDistinctScenesMetadata: `${PUBLISHED_SCENE_METADATA}/distinct`,
  myPublishedScenesMetadata: `${PUBLISHED_SCENE_METADATA}/my`,
  sceneContent: (id) => `${SCENE_CONTENT}/${id}`,
  scenesMetadata: SCENE_METADATA,
  sceneMetadata: (id) => `${SCENE_METADATA}/${id}`,
  myScenesMetadata: `${SCENE_METADATA}/my`,
  scenePublish: (id) => `${SCENE_ACTIONS}/${id}/publish`,
  sceneUnpublish: (id) => `${BASE_PATH}/${id}/unpublish`,
  deleteScene: (id) => `${SCENE_ACTIONS}/${id}/delete`,
  slot: (id) => `${BASE_PATH}/slot/${id}`,
  mySceneSlots: `${BASE_PATH}/slot/my`,
  availableSceneSlot: `${BASE_PATH}/slot/available`,
  premadeScenes: `${BASE_PATH}/premadescene`,
  premadeScene: (id) => id && `${BASE_PATH}/premadescene/${id}`,
};

export default sceneManagement;
