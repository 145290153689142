/* eslint-disable react-hooks/exhaustive-deps */
import axiosRetry from 'axios-retry';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

const useAxios = ({
  method,
  url,
  body,
  headers,
  onSuccess,
  onError,
  paramsSerializer,
  retries = 1,
  dependencies = [],
}) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [status, setStatus] = useState();

  useEffect(() => {
    if (url) {
      const axiosInstance = axios.create();

      if (retries > 1) {
        // Set up axios-retry on the specific instance
        axiosRetry(axiosInstance, {
          retries: 3, // Number of retries
          retryDelay: axiosRetry.exponentialDelay,
          retryCondition: (err) =>
            // Retry on specific conditions (e.g., network errors or 5xx responses)
            err.response && err.response.status >= 400,
        });
      }

      axiosInstance({
        method,
        url,
        headers,
        params: method.toUpperCase() === 'GET' && body,
        data: method.toUpperCase() !== 'GET' && body,
        paramsSerializer,
      })
        .then((response) => {
          setLoading(false);
          setStatus(response.status);
          setData(response.data);

          if (typeof onSuccess === 'function') {
            onSuccess(response.data, response.headers, response.status);
          }
        })
        .catch((err) => {
          setLoading(false);
          setStatus(err.response?.status);
          setError(err);

          if (typeof onError === 'function') {
            onError(err);
          }
        });
    }
  }, [method, url, JSON.stringify(body), JSON.stringify(headers), ...dependencies]);

  return { data, loading, error, status };
};

export const useAxiosCallback = ({ method, url, headers, paramsSerializer, dependencies = [] }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [status, setStatus] = useState();

  const executeRequest = useCallback(
    ({ instanceUrl, body, onSuccess, onError, retries = 0 } = {}) => {
      const axiosInstance = axios.create();

      if (retries > 0) {
        // Set up axios-retry on the specific instance
        axiosRetry(axiosInstance, {
          retries, // Number of retries
          retryDelay: () => 1000, // Time between retries in milliseconds
          retryCondition: (err) =>
            // Retry on specific conditions (e.g., network errors or 5xx responses)
            err.response && err.response.status >= 400,
        });
      }

      setLoading(true);

      axiosInstance({
        method,
        url: instanceUrl || url,
        headers,
        params: method.toUpperCase() === 'GET' && body,
        data: method.toUpperCase() !== 'GET' && body,
        paramsSerializer,
      })
        .then((response) => {
          setLoading(false);
          setStatus(response.status);
          setData(response.data);

          if (typeof onSuccess === 'function') {
            onSuccess(response.data, response.headers, response.status);
          }
        })
        .catch((err) => {
          setLoading(false);
          setStatus(err.response?.status);
          setError(err);

          if (typeof onError === 'function') {
            onError(err);
          }
        });
    },
    [method, url, JSON.stringify(headers), ...dependencies],
  );

  return { data, executeRequest, loading, error, status };
};

export default useAxios;
