import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';
import {
  ProfileMenuContainer,
  ProfileMenuDrawer,
  ProfilePicture,
  useBreakpoints,
} from '@artsteps/components';
import CustomLink from '../../../infrastructure/subdomain-routing/CustomLink';
import apis from '../../../server/endpoints/apis';
import isValidUrl from '../../../utils/isValidUrl';
import useRoutes from '../../../custom-hooks/useRoutes/useRoutes';
import useMenuItems from './useMenuItems';

const useStyles = createStyles((theme) => ({
  drawer: {
    background: `${theme.colors.surfaces[4]} 0% 0% no-repeat padding-box`,
    backdropFilter: 'blur(50px)',
  },
  mainContent: {
    flexGrow: 1,
    marginTop: '32px',
  },
  name: {
    whiteSpace: 'normal',
    textAlign: 'right',
    lineHeight: 1.25,
  },
  mainMenuItem: {
    margin: '14px 0px',
    fontWeight: 'bold',
    cursor: 'pointer',
    color: theme.colors.typography[6],
    display: 'block',
    textAlign: 'right',
    fontSize: '22px',
    lineHeight: '26px',

    '&:first-of-type': {
      marginTop: 0,
    },
  },
  secondaryMenuItem: {
    color: theme.colors.typography[6],
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '22px',
    textAlign: 'right',
  },
}));

const UserProfileMenu = ({ isOpen, onClose, user = {} }) => {
  const { classes, theme } = useStyles();
  const { mainMenuItems, bottomMenuItems } = useMenuItems(user?.userId);
  const { routes } = useRoutes();

  const {
    is: { sm: isSm },
    largerThan: { sm: largerThanSm },
  } = useBreakpoints(theme);

  const size = useMemo(() => {
    if (largerThanSm) {
      return '400px';
    }
    if (isSm) {
      return '50%';
    }
    return '100%';
  }, [isSm, largerThanSm]);

  const profilePictureSrc = isValidUrl(user.profilePicture)
    ? user.profilePicture
    : apis.fileManagement.file(user.profilePicture);

  return (
    <ProfileMenuDrawer isOpen={isOpen} size={size} className={classes.drawer}>
      <ProfileMenuContainer onCloseProfileDrawer={onClose}>
        <CustomLink onClick={onClose} route={`${routes.USER}/${user?.userId}`}>
          <ProfilePicture
            classNames={{
              label: classes.name,
            }}
            name={user.name}
            image={user.profilePicture && profilePictureSrc}
            size="120px"
            shouldDisplayName
            labelPlacement="bottom-end"
            labelStyle={{
              color: theme.colors.typography[6],
              fontSize: '32px',
              fontWeight: '900',
            }}
          />
        </CustomLink>
        <div className={classes.mainContent}>
          {mainMenuItems.map(({ label, to }) => (
            <CustomLink key={label} className={classes.mainMenuItem} onClick={onClose} route={to}>
              {label}
            </CustomLink>
          ))}
        </div>
        {bottomMenuItems.map(({ label, to, onClick }) => (
          <CustomLink
            key={label}
            className={classes.secondaryMenuItem}
            onClick={onClick || onClose}
            route={to}
          >
            {label}
          </CustomLink>
        ))}
      </ProfileMenuContainer>
    </ProfileMenuDrawer>
  );
};

UserProfileMenu.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
};

export default UserProfileMenu;
