const BASE_PATH = '/api/marketplaceService';

const marketplaceService = {
  wallet: `${BASE_PATH}/wallet/byuserid`,
  marketplaceItems: `${BASE_PATH}/marketplaceitem`,
  serviceItems: `${BASE_PATH}/serviceitem`,
  purchasedSceneItems: (sceneId) => sceneId && `${BASE_PATH}/purchase/scene/${sceneId}`,
  purchasedDistrictItems: (districtId) =>
    districtId && `${BASE_PATH}/purchase/district/${districtId}`,
  internalTransaction: `${BASE_PATH}/internaltransaction`,
  paddleProducts: `${BASE_PATH}/paddle/products`,
  cancelSubscription: `${BASE_PATH}/paddle/cancel/subscription`,
  enableAutoRenewal: `${BASE_PATH}/paddle/removecancelationsubscription`,
  updatePaymentMethod: `${BASE_PATH}/paddle/updatepaymentmethod/subscription`,
  upgradeSubscriptionPlanPreview: `${BASE_PATH}/paddle/previewsubscriptionupdate`,
  upgradeSubscriptionPlan: `${BASE_PATH}/paddle/updatesubscription`,
  mySubscriptionPlan: `${BASE_PATH}/externaltransaction/usersubscription`,
  userPaymentHistory: `${BASE_PATH}/externaltransaction/userproducts`,
  cardDetails: `${BASE_PATH}/externaltransaction/getuserslastcarddetails`,
};

export default marketplaceService;
