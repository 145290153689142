import {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import UnityInspector from '../../../common/unity-inspector';

const UnityInspectorContext = createContext({
  unityInspectorRef: { current: {} },
  artifactDescription: undefined,
  setArtifactDescription: () => {},
  artifactType: undefined,
  setArtifactType: () => {},
  moveInspectorInElement: () => {},
  removeInspector: () => {},
  sendInspectorMessage: () => {},
  addInspectorEventListener: () => {},
  removeInspectorEventListener: () => {},
});

export const UnityInspectorProvider = ({ children }) => {
  const [artifactDescription, setArtifactDescription] = useState();
  const [artifactType, setArtifactType] = useState();
  const unityInspectorRef = useRef(null);
  const rootRef = useRef(null);
  const [sendInspectorMessage, setSendInspectorMessage] = useState();
  const [addInspectorEventListener, setAddInspectorEventListener] = useState();
  const [removeInspectorEventListener, setRemoveInspectorEventListener] = useState();

  useEffect(() => {
    unityInspectorRef.current = document.createElement('div');
    unityInspectorRef.current.setAttribute('id', 'inspector-container');
    unityInspectorRef.current.setAttribute('style', 'width:100%;height:100%');
    unityInspectorRef.current.style.display = 'none';
    rootRef.current = createRoot(unityInspectorRef.current);

    const root = document.getElementById('root');
    if (root) {
      root.appendChild(unityInspectorRef.current);
    }

    return () => {
      if (unityInspectorRef.current) {
        unityInspectorRef.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (rootRef.current) {
      rootRef.current.render(
        <UnityInspector
          artifactDescription={artifactDescription}
          artifactType={artifactType}
          setSendInspectorMessage={setSendInspectorMessage}
          setAddInspectorEventListener={setAddInspectorEventListener}
          setRemoveInspectorEventListener={setRemoveInspectorEventListener}
        />,
      );
    }
  }, [artifactDescription, artifactType]);

  const moveInspectorInElement = useCallback((element) => {
    if (element) {
      element.appendChild(unityInspectorRef.current);
      unityInspectorRef.current.style.display = 'block';
    }
  }, []);

  const removeInspector = useCallback(() => {
    unityInspectorRef.current.style.display = 'none';
    document.getElementById('root')?.appendChild(unityInspectorRef.current);
  }, []);

  const value = useMemo(
    () => ({
      unityInspectorRef,
      artifactDescription,
      setArtifactDescription,
      artifactType,
      setArtifactType,
      moveInspectorInElement,
      removeInspector,
      sendInspectorMessage,
      addInspectorEventListener,
      removeInspectorEventListener,
    }),
    [
      artifactDescription,
      artifactType,
      moveInspectorInElement,
      removeInspector,
      sendInspectorMessage,
      addInspectorEventListener,
      removeInspectorEventListener,
    ],
  );
  return <UnityInspectorContext.Provider value={value}>{children}</UnityInspectorContext.Provider>;
};

UnityInspectorProvider.propTypes = {
  children: PropTypes.node,
};

export const useUnityInspectorContext = () => {
  const context = useContext(UnityInspectorContext);
  if (!context) {
    throw new Error('useUnityInspectorContext must be used within a UnityProvider');
  }

  return context;
};
