const NAME = 'Viewer';
const BASE_PATH = `/files/builds/3d/${NAME}`;
const BUILD_PATH = `${BASE_PATH}/Build`;

const viewer = {
  loader: {
    development: `${BUILD_PATH}/${NAME}.loader.js`,
    test: 'https://unity.builder.loader',
    production: `${BUILD_PATH}/${NAME}.loader.js`,
  },
  data: {
    development: `${BUILD_PATH}/${NAME}.data.unityweb`,
    test: 'https://unity.builder.data',
    production: `${BUILD_PATH}/${NAME}.data.unityweb`,
  },
  framework: {
    development: `${BUILD_PATH}/${NAME}.framework.js.unityweb`,
    test: 'https://unity.builder.framework',
    production: `${BUILD_PATH}/${NAME}.framework.js.unityweb`,
  },
  wasm: {
    development: `${BUILD_PATH}/${NAME}.wasm.unityweb`,
    test: 'https://unity.builder.wasm',
    production: `${BUILD_PATH}/${NAME}.wasm.unityweb`,
  },
  streamingAssets: {
    development: `${BASE_PATH}/StreamingAssets`,
    test: 'https://unity.builder.wasm',
    production: `${BASE_PATH}/StreamingAssets`,
  },
};

export default viewer;
