const basePath = '/cms';

const resourcesManagement = {
  baseUrl: basePath,
  faq: `${basePath}/api/faqs`,
  categories: `${basePath}/api/categories`,
  article: (id) => `${basePath}/api/articles/${id}`,
  articles: `${basePath}/api/articles`,
  author: (id) => `${basePath}/api/authors/${id}`,
  authors: `${basePath}/api/authors`,
};

export default resourcesManagement;
