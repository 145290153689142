var customArrowPosition = function customArrowPosition(targetOffsetRef) {
  var _targetOffsetRef$curr, _targetOffsetRef$curr2, _targetOffsetRef$curr3, _targetOffsetRef$curr4, _targetOffsetRef$curr5, _targetOffsetRef$curr6, _targetOffsetRef$curr7, _targetOffsetRef$curr8;
  return {
    top: {
      bottom: '-8px',
      left: 'calc(50% - 7px)',
      transform: 'rotate(180deg)'
    },
    'top-start': {
      bottom: '-8px',
      left: parseInt(((targetOffsetRef == null ? void 0 : (_targetOffsetRef$curr = targetOffsetRef.current) == null ? void 0 : _targetOffsetRef$curr.offsetWidth) || 0) / 2, 10) + "px",
      transform: 'rotate(180deg)'
    },
    'top-end': {
      bottom: '-8px',
      right: parseInt(((targetOffsetRef == null ? void 0 : (_targetOffsetRef$curr2 = targetOffsetRef.current) == null ? void 0 : _targetOffsetRef$curr2.offsetWidth) || 0) / 2, 10) + "px",
      transform: 'rotate(180deg)'
    },
    bottom: {
      top: '-8px',
      left: 'calc(50% - 7px)'
    },
    'bottom-start': {
      top: '-8px',
      left: parseInt(((targetOffsetRef == null ? void 0 : (_targetOffsetRef$curr3 = targetOffsetRef.current) == null ? void 0 : _targetOffsetRef$curr3.offsetWidth) || 0) / 2, 10) + "px"
    },
    'bottom-end': {
      top: '-8px',
      right: parseInt(((targetOffsetRef == null ? void 0 : (_targetOffsetRef$curr4 = targetOffsetRef.current) == null ? void 0 : _targetOffsetRef$curr4.offsetWidth) || 0) / 2, 10) + "px"
    },
    left: {
      top: 'calc(50% - 7px)',
      right: '-12px',
      transform: 'rotate(90deg)'
    },
    'left-start': {
      top: parseInt(((targetOffsetRef == null ? void 0 : (_targetOffsetRef$curr5 = targetOffsetRef.current) == null ? void 0 : _targetOffsetRef$curr5.offsetHeight) || 0) / 2, 10) + "px",
      right: '-12px',
      transform: 'rotate(90deg)'
    },
    'left-end': {
      bottom: parseInt(((targetOffsetRef == null ? void 0 : (_targetOffsetRef$curr6 = targetOffsetRef.current) == null ? void 0 : _targetOffsetRef$curr6.offsetHeight) || 0) / 2, 10) + "px",
      right: '-12px',
      transform: 'rotate(90deg)'
    },
    right: {
      top: 'calc(50% - 7px)',
      left: '-12px',
      transform: 'rotate(270deg)'
    },
    'right-start': {
      top: parseInt(((targetOffsetRef == null ? void 0 : (_targetOffsetRef$curr7 = targetOffsetRef.current) == null ? void 0 : _targetOffsetRef$curr7.offsetHeight) || 0) / 2, 10) + "px",
      left: '-12px',
      transform: 'rotate(270deg)'
    },
    'right-end': {
      bottom: parseInt(((targetOffsetRef == null ? void 0 : (_targetOffsetRef$curr8 = targetOffsetRef.current) == null ? void 0 : _targetOffsetRef$curr8.offsetHeight) || 0) / 2, 10) + "px",
      left: '-12px',
      transform: 'rotate(270deg)'
    }
  };
};
export default customArrowPosition;