import { useOidc } from '@axa-fr/react-oidc';
import useRoutes from '../../../custom-hooks/useRoutes/useRoutes';
import { useTranslation } from '../../../infrastructure/translation/TranslationProvider';
import {
  ACTIVITY,
  CONNECTIONS,
  DISTRICTS,
  FAVORITES,
  GROUPS,
  HISTORY,
  IMAGES,
  LIBRARY,
  MY_DISTRICTS,
  PUBLISHED,
  SPACES,
} from '../../../server/endpoints/routes';

const TRANSLATION_BASE = 'Header.UserProfileMenu';

const useMenuItems = (userId) => {
  const { logout } = useOidc();
  const { t } = useTranslation();
  const { routes } = useRoutes();
  const BASE_ACCOUNT_ROUTE = `${routes.USER}/${userId}`;

  const mainMenuItems = [
    {
      label: t(`${TRANSLATION_BASE}.Avatar`),
      to: `${BASE_ACCOUNT_ROUTE}?edit_avatar=true`,
    },
    {
      label: t(`${TRANSLATION_BASE}.Spaces`),
      to: `${BASE_ACCOUNT_ROUTE}/${SPACES}/${PUBLISHED}`,
    },
    {
      label: t(`${TRANSLATION_BASE}.Wallet`),
      to: `${routes.WALLET}`,
    },
    {
      label: t(`${TRANSLATION_BASE}.Districts`),
      to: `${BASE_ACCOUNT_ROUTE}/${DISTRICTS}/${MY_DISTRICTS}`,
    },
    {
      label: t(`${TRANSLATION_BASE}.Groups`),
      to: `${BASE_ACCOUNT_ROUTE}/${CONNECTIONS}/${GROUPS}`,
    },
    {
      label: t(`${TRANSLATION_BASE}.MyLibrary`),
      to: `${BASE_ACCOUNT_ROUTE}/${LIBRARY}/${IMAGES}`,
    },
    {
      label: t(`${TRANSLATION_BASE}.Favorites`),
      to: `${BASE_ACCOUNT_ROUTE}/${ACTIVITY}/${FAVORITES}`,
    },
    {
      label: t(`${TRANSLATION_BASE}.History`),
      to: `${BASE_ACCOUNT_ROUTE}/${ACTIVITY}/${HISTORY}`,
    },
  ];

  const bottomMenuItems = [
    {
      label: t(`${TRANSLATION_BASE}.Settings`),
      to: `${routes.SETTINGS}`,
    },
    {
      label: t(`${TRANSLATION_BASE}.Language`),
      to: `${BASE_ACCOUNT_ROUTE}`,
    },
    {
      label: t(`${TRANSLATION_BASE}.LogOut`),
      onClick: () => logout(),
    },
  ];

  return { mainMenuItems, bottomMenuItems };
};

export default useMenuItems;
