import { createStyles } from '@mantine/core';
import { Button } from '@artsteps/components';
import { Form } from '@artsteps/form';
import { useCallback } from 'react';
import ModelDropzone from '../../../common/dropzone-model3d/ModelDropzone';
import { useUnity } from '../../../infrastructure/unity-provider/UnityProvider';
import { unityBrokers } from '../../../infrastructure/unity-provider/unity-brokers/brokers';

const useStyles = createStyles(() => ({
  form: {
    width: '600px',
    height: '600px',
  },
}));

const Uploader = () => {
  const { classes } = useStyles();
  const { sendMessage } = useUnity();

  const handleChange = () => {
    // console.log('Model Inspector', e);
  };

  const handleInspectorReady = () => {
    // console.log('Model Inspector', e);
  };

  const handleModelExported = useCallback((imposter, model) => {
    const url = URL.createObjectURL(model);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'exportedModel.glb';
    link.click();
  }, []);

  const handleThumbnailExported = useCallback((thumbnail) => {
    const url = URL.createObjectURL(thumbnail);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'exportedThumbnail.png';
    link.click();
  }, []);

  const handleExportModel = () => {
    sendMessage(unityBrokers.inspector, 'ExportModel');
  };
  const handleThumbnail = () => {
    sendMessage(unityBrokers.inspector, 'CaptureThumbnail');
  };

  return (
    <Form className={classes.form}>
      <ModelDropzone
        fileInputName="fileInput"
        urlInputName="urlInput"
        onChange={handleChange}
        onInspectorReady={handleInspectorReady}
        onModelExported={handleModelExported}
        onThumbnailExported={handleThumbnailExported}
        shouldDisplayFilePills
      />
      <Button label="Export" onClick={handleExportModel} />
      <Button label="Thumbnail" onClick={handleThumbnail} />
    </Form>
  );
};

Uploader.propTypes = {};

export default Uploader;
