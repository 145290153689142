import { useTranslation } from '../../../infrastructure/translation/TranslationProvider';
import ROUTES from '../../../server/endpoints/routes';

// const useFooterItems = (userId) => {
const useFooterItems = () => {
  const { t } = useTranslation();

  // const BASE_ACCOUNT_ROUTE = `${ROUTES.USER}/${userId}`;

  const pageItems = [
    {
      label: t('Global.Home.Label'),
      to: ROUTES.HOME,
    },
    {
      label: t('Footer.Pages.Explore'),
      to: ROUTES.EXPLORE,
    },
    {
      label: t('Footer.Pages.Create'),
      to: ROUTES.COMINGSOON,
    },
    {
      label: t('Footer.Pages.LearningCenter'),
      to: ROUTES.COMINGSOON,
    },
    {
      label: t('Footer.Info.Contact'),
      to: ROUTES.CONTACT,
    },
    {
      label: t('Footer.Info.PrivacyPolicy'),
      to: ROUTES.COMINGSOON,
    },
    {
      label: t('Footer.UserProfile.Avatar'),
      to: ROUTES.COMINGSOON,
    },
    {
      label: t('Footer.UserProfile.Spaces'),
      to: ROUTES.COMINGSOON,
    },
    {
      label: t('Footer.UserProfile.Districts'),
      to: ROUTES.COMINGSOON,
    },
    {
      label: t('Footer.UserProfile.Favorites'),
      to: ROUTES.COMINGSOON,
    },
    {
      label: t('Footer.UserProfile.Groups'),
      to: ROUTES.COMINGSOON,
    },
    {
      label: t('Footer.UserProfile.Library'),
      to: ROUTES.COMINGSOON,
    },
    {
      label: t('Footer.Info.FAQ'),
      to: ROUTES.COMINGSOON,
    },
    {
      label: t('Footer.Info.Help'),
      to: ROUTES.COMINGSOON,
    },
    // {
    //   label: t('Footer.Info.Settings'),
    //   to: ROUTES.COMINGSOON,
    // },
    {
      label: t('Footer.Info.Security'),
      to: ROUTES.COMINGSOON,
    },
    {
      label: t('Footer.Info.Language'),
      to: ROUTES.COMINGSOON,
    },
    // {
    //   label: t('AccountPage.AccountCard.EditInfoModal.ProfileManagement.ChangePassword'),
    //   to: ROUTES.COMINGSOON,
    // },
  ];

  return { pageItems };
};

export default useFooterItems;
