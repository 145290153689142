export const SPACES = 'spaces';
export const DISTRICTS = 'districts';
export const LIBRARY = 'library';
export const CONNECTIONS = 'connections';
export const ACTIVITY = 'activity';
export const PUBLISHED = 'published';
export const DRAFTS = 'drafts';
export const IMAGES = 'images';
export const VIDEOS = 'videos';
export const MODELS = '3d-models';
export const TEXTS = '3d-texts';
export const HISTORY = 'history';
export const FAVORITES = 'favorites';
export const FOLLOWERS = 'followers';
export const FOLLOWING = 'following';
export const GROUPS = 'groups';
export const MY_DISTRICTS = 'my-districts';
export const OTHERS_DISTRICTS = 'others-districts';
export const FOLLOWING_DISTRICTS = 'following-districts';
export const MEMBERS = 'members';
export const ALL = 'all';
export const INVITATIONS = 'invitations';

const ROUTES = {
  HOME: '/',
  COMINGSOON: '/coming-soon',
  CONTACT: '/contact-us',
  EXPLORE: '/explore',
  WALLET: '/wallet',
  SETTINGS: '/settings',
  EDITOR: '/curate',
  VIEWER: '/viewer',
  USER: '/user',
  RESOURCES: {
    BASE: '/resources',
    BLOG: '/resources/blog',
    GUIDES: '/resources/guides',
    FAQS: '/resources/faqs',
    WHITEPAPER: '/resources/whitepaper',
    GLOSSARY: '/resources/glossary',
  },
  ACCOUNT: {
    SPACES: {
      PUBLISHED: `/${SPACES}/${PUBLISHED}`,
      DRAFTS: `/${SPACES}/${DRAFTS}`,
    },
    LIBRARY: {
      IMAGES: `/${LIBRARY}/${IMAGES}`,
      VIDEOS: `/${LIBRARY}/${VIDEOS}`,
      MODELS: `/${LIBRARY}/${MODELS}`,
      TEXTS: `/${LIBRARY}/${TEXTS}`,
    },
    ACTIVITY: {
      HISTORY: `/${ACTIVITY}/${HISTORY}`,
      FAVORITES: `/${ACTIVITY}/${FAVORITES}`,
    },
    CONNECTIONS: {
      FOLLOWERS: `/${CONNECTIONS}/${FOLLOWERS}`,
      FOLLOWING: `/${CONNECTIONS}/${FOLLOWING}`,
      GROUPS: `/${CONNECTIONS}/${GROUPS}`,
    },
    DISTRICTS: {
      MYDISTRICTS: `/${DISTRICTS}/${MY_DISTRICTS}`,
      OTHERSDISTRICTS: `/${DISTRICTS}/${OTHERS_DISTRICTS}`,
      FOLLOWINGDISTRICTS: `/${DISTRICTS}/${FOLLOWING_DISTRICTS}`,
    },
  },
  DISTRICT: {
    HOME: '/',
    SPACES: {
      PUBLISHED: `/${SPACES}/${PUBLISHED}`,
      DRAFTS: `/${SPACES}/${DRAFTS}`,
    },
    LIBRARY: {
      IMAGES: `/${LIBRARY}/${IMAGES}`,
      VIDEOS: `/${LIBRARY}/${VIDEOS}`,
      MODELS: `/${LIBRARY}/${MODELS}`,
      TEXTS: `/${LIBRARY}/${TEXTS}`,
    },
    MEMBERS: {
      ALL: `/${MEMBERS}/${ALL}`,
      INVITATIONS: `/${MEMBERS}/${INVITATIONS}`,
    },
    GROUPS: `/${GROUPS}`,
  },
  PRICING: '/pricing',
  DEV_UNITY_UPLOADER: '/dev-unity-uploader',
  DEV_DEEP_LINK: '/deep-linkin',
};

export default ROUTES;
