export default {
  xsmall: {
    min: '0',
    max: '32.5em'
  },
  // 0px .. 520px
  small: {
    min: '32.5625em',
    max: '57.5em'
  },
  // 521px .. 920px
  medium: {
    min: '57.5625em',
    max: '83.75em'
  },
  // 921px .. 1340px
  large: {
    min: '83.8125em',
    max: '95em'
  },
  // 1341px .. 1520px
  xlarge: {
    min: '95.0625em',
    max: '160em'
  } // 1521px .. 2560px
};