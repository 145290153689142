import { createStyles } from '@mantine/core';
import { mediaQueries } from '@artsteps/components';
import { useTranslation } from '../../infrastructure/translation/TranslationProvider';
import VisibilityTab from './visibility/VisibilityTab';

const useStyles = createStyles(() => ({
  wrapper: {
    padding: '174px 272px',
    display: 'flex',
    flexDirection: 'column',
    gap: '120px',
    background:
      'transparent linear-gradient(180deg, #FFFFFF 0%, #EFEFEF 100%) 0% 0% no-repeat padding-box',

    [mediaQueries.medium.down]: {
      padding: '174px 80px',
    },
  },
  title: {
    fontSize: '38px',
    lineHeight: '43px',
    fontWeight: 'bold',
    letterSpacing: '0px',
  },
}));

const SettingsPage = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper} data-testid="settings-page">
      <div className={classes.title}>{t('SettingsPage.Title')}</div>
      <VisibilityTab />
    </div>
  );
};

export default SettingsPage;
