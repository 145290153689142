import { createStyles } from '@mantine/core';
import apis from '../../../server/endpoints/apis';
import { UnityProvider } from '../../../infrastructure/unity-provider/UnityProvider';
import Uploader from './Uploader';

const useStyles = createStyles(() => ({
  wrapper: {
    height: '100%',
    width: '100%',
  },
}));

const filesUrls = {
  loaderUrl: apis.inspector.loader,
  dataUrl: apis.inspector.data,
  frameworkUrl: apis.inspector.framework,
  codeUrl: apis.inspector.wasm,
  streamingAssetsUrl: apis.inspector.streamingAssets,
};

const UnityUploaderDevPage = () => {
  const { classes } = useStyles();

  return (
    <UnityProvider className={classes.wrapper} filesUrls={filesUrls}>
      <Uploader />
    </UnityProvider>
  );
};

export default UnityUploaderDevPage;
