export const ASSET_TYPE = {
  picture: 'picture',
  video: 'video',
  model: 'model3d',
  text: 'text3d',
  portal: 'portal',
};

export const MANY = {
  [ASSET_TYPE.picture]: 'pictures',
  [ASSET_TYPE.video]: 'videos',
  [ASSET_TYPE.model]: 'models3d',
  [ASSET_TYPE.text]: 'texts3d',
  [ASSET_TYPE.portal]: 'portals',
};

export const ASSET_ID_LABEL = {
  pictureId: 'pictureId',
  videoId: 'videoId',
  model3dId: 'model3dId',
  text3dId: 'text3dId',
  portalId: 'portalId',
};
