import { useCallback, useEffect, useState } from 'react';
var useScroll = function useScroll(_ref) {
  var elementRef = _ref.elementRef;
  var _useState = useState(false),
    isScrolling = _useState[0],
    setIsScrolling = _useState[1];
  var scrollToStart = function scrollToStart(_ref2) {
    var _elementRef$current;
    var _ref2$start = _ref2.start,
      start = _ref2$start === void 0 ? 'bottom' : _ref2$start,
      onScrollDone = _ref2.onScrollDone;
    var top = start === 'top' ? 0 : elementRef == null ? void 0 : elementRef.current.scrollHeight;
    elementRef == null ? void 0 : (_elementRef$current = elementRef.current) == null ? void 0 : _elementRef$current.scroll({
      top: top,
      behavior: 'smooth'
    });
    if (typeof onScrollDone === 'function') {
      onScrollDone();
    }
  };
  var handleScroll = useCallback(function (_ref3) {
    var _ref3$target = _ref3.target,
      scrollHeight = _ref3$target.scrollHeight,
      scrollTop = _ref3$target.scrollTop,
      clientHeight = _ref3$target.clientHeight;
    clearTimeout(window.scrollEndTimer);
    window.scrollEndTimer = setTimeout(function () {
      if (scrollHeight >= clientHeight + scrollTop + 80) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    }, 100);
  }, []);
  useEffect(function () {
    var wrapperElement = elementRef == null ? void 0 : elementRef.current;
    wrapperElement.addEventListener('scroll', handleScroll);
    return function () {
      return wrapperElement.removeEventListener('scroll', handleScroll);
    };
  }, [elementRef, handleScroll]);
  return {
    isScrolling: isScrolling,
    scrollToStart: scrollToStart
  };
};
export default useScroll;