const BASE_PATH = '/api/storyTellingService';

const storyTelling = {
  guidedTours: `${BASE_PATH}/guidedtour`,
  guidedTour: (guidedTourId) => `${BASE_PATH}/guidedtour/${guidedTourId}`,
  guidedTourPoints: `${BASE_PATH}/guidedtourpoint`,
  guidedTourPoint: (guidedTourPointId) => `${BASE_PATH}/guidedtourpoint/${guidedTourPointId}`,
};

export default storyTelling;
