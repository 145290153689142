import PropTypes from 'prop-types';
var sceneDataPropTypes = PropTypes.shape({
  adultContent: PropTypes.bool,
  areas: PropTypes.arrayOf(PropTypes.any),
  categories: PropTypes.arrayOf(PropTypes.number),
  categoryObjects: PropTypes.arrayOf(PropTypes.any),
  createdAt: PropTypes.string,
  customSpace: PropTypes.shape({
    levels: PropTypes.arrayOf(PropTypes.object),
    materialOverrides: PropTypes.arrayOf(PropTypes.any),
    skyUri: PropTypes.string,
    terrainUri: PropTypes.string
  }),
  description: PropTypes.string,
  fontUris: PropTypes.arrayOf(PropTypes.string),
  guidedTourUris: PropTypes.arrayOf(PropTypes.any),
  likes: PropTypes.number,
  links: PropTypes.arrayOf(PropTypes.string),
  members: PropTypes.arrayOf(PropTypes.string),
  model3ds: PropTypes.arrayOf(PropTypes.object),
  owner: PropTypes.shape({
    name: PropTypes.string,
    profilePicture: PropTypes.string,
    userId: PropTypes.string
  }),
  photoGallery: PropTypes.arrayOf(PropTypes.any),
  pictures: PropTypes.arrayOf(PropTypes.any),
  props: PropTypes.arrayOf(PropTypes.any),
  publishedAtDistricts: PropTypes.arrayOf(PropTypes.any),
  publishedPublic: PropTypes.bool,
  restrictedToGroups: PropTypes.arrayOf(PropTypes.any),
  sceneId: PropTypes.string,
  spaceType: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.string),
  texts3d: PropTypes.arrayOf(PropTypes.object),
  thumbnailUri: PropTypes.string,
  title: PropTypes.string,
  updatedAt: PropTypes.string,
  videos: PropTypes.arrayOf(PropTypes.any),
  views: PropTypes.number
});
export default sceneDataPropTypes;