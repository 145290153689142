import PropTypes from 'prop-types';
import { ThemeProvider as ArtThemeProvider } from '@artsteps/components';
import { createContext, useState, useContext, useMemo } from 'react';
import { useDistrict } from '../district-provider';
import downloadFontFamily from './utils/downloadFontFamily';

const ThemeContext = createContext({});

const ThemeProvider = ({ children }) => {
  const { districtData } = useDistrict();
  const [theme, setTheme] = useState(districtData?.styles && JSON.parse(districtData?.styles));

  const context = useMemo(() => ({ theme, setTheme }), [theme]);

  if (theme?.fontFamily) {
    downloadFontFamily(theme.fontFamily);
  }

  return (
    <ThemeContext.Provider value={context}>
      <ArtThemeProvider theme={{ other: theme }}>{children}</ArtThemeProvider>
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
};

ThemeProvider.propTypes = {
  children: PropTypes.object,
};

export { ThemeProvider, useTheme };
