import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';
import MainHeaderSection from './MainHeaderSection';
import LeftHeaderSection from './LeftHeaderSection';
import User from './user/User';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    top: '84px',
    left: 0,
    width: '100%',
    height: '640px',
    backgroundColor: theme.colors.typography[7],
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '64px 0',
  },
  createSpaceButton: {
    marginTop: '80px',
    marginBottom: '24px',
  },
}));

const HeaderBurgerMenu = ({ onClose }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <MainHeaderSection onClose={onClose} />
        <LeftHeaderSection
          classNames={{ createSpace: classes.createSpaceButton }}
          hideOptions={{ shouldHideLogo: true, shouldHideGoToMetasteps: true }}
        />
        <User />
      </div>
    </div>
  );
};

HeaderBurgerMenu.propTypes = {
  onClose: PropTypes.func,
};

export default HeaderBurgerMenu;
