import { createStyles } from '@mantine/core';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './helpers/ScrollToTop';
import Header from './Header';
import Footer from './Footer';
import Content from './Routes';

const useStyles = createStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  content: {
    flexGrow: 1,
  },
}));

const Main = () => {
  const { classes } = useStyles();

  return (
    <Router>
      <ScrollToTop />
      <div className={classes.wrapper}>
        <Header />
        <div className={classes.content}>
          <Content />
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default Main;
