import { ProfilePicture } from '@artsteps/components';
import { useDisclosure } from '@mantine/hooks';
import { useUserContext } from '../../../infrastructure/user-context';
import apis from '../../../server/endpoints/apis';
import isValidUrl from '../../../utils/isValidUrl';
import UserProfileMenu from './UserProfileMenu';

const User = () => {
  const { userData } = useUserContext();

  const [isProfileMenuOpen, { close: closeMenu, open: openMenu }] = useDisclosure(false);

  const profilePictureSrc = isValidUrl(userData?.profilePicture)
    ? userData?.profilePicture
    : apis.fileManagement.file(userData?.profilePicture);

  return (
    <>
      <ProfilePicture
        size="40px"
        name={userData?.name}
        image={userData?.profilePicture && profilePictureSrc}
        onClick={openMenu}
      />
      <UserProfileMenu isOpen={isProfileMenuOpen} onClose={closeMenu} user={userData} />
    </>
  );
};

export default User;
