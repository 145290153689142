import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';
import { Button, Logo, mediaQueries } from '@artsteps/components';
import useRoutes from '../../custom-hooks/useRoutes/useRoutes';
import { useDistrict } from '../../infrastructure/district-provider';
import { useTranslation } from '../../infrastructure/translation/TranslationProvider';
import apis from '../../server/endpoints/apis';
import ROUTES from '../../server/endpoints/routes';
import isValidUrl from '../../utils/isValidUrl';

const useStyles = createStyles((_, { districtData }) => ({
  root: {
    position: 'relative',
    display: 'flex',
    gap: districtData ? '40px' : '16px',
    alignItems: 'center',

    [mediaQueries.small.down]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  logo: {
    marginTop: !districtData?.photo && '-10px',
    minHeight: '48px',

    [mediaQueries.small.down]: {
      marginTop: 0,
      minHeight: '40px',
    },
  },
  buttons: {
    height: '42px',
    letterSpacing: '1.6px',
  },
}));

const LeftHeaderSection = ({
  classNames = {},
  hideOptions = {
    shouldHideCreateSpace: false,
    shouldHideLogo: false,
    shouldHideGoToMetasteps: true,
  },
  shouldShrinkLogo = false,
}) => {
  const { districtData } = useDistrict();
  const { classes, cx } = useStyles({ districtData });
  const { t } = useTranslation();
  const { routes } = useRoutes();

  const { shouldHideCreateSpace, shouldHideLogo, shouldHideGoToMetasteps } = hideOptions;

  return (
    <div className={cx(classes.root, classNames.root)}>
      {!shouldHideLogo && (
        <Logo
          className={cx(classes.logo, classNames.logo)}
          href={ROUTES.HOME}
          shouldShrink={shouldShrinkLogo}
          districtLogo={
            isValidUrl(districtData?.photo)
              ? districtData?.photo
              : apis.fileManagement.file(districtData?.photo)
          }
        />
      )}
      {!shouldHideCreateSpace && (
        <Button
          classNames={{ root: cx(classes.buttons, classNames.createSpace) }}
          href={ROUTES.EDITOR}
          label={t('Menu.Button.CreateSpace')}
          variant="gradient"
          isUpperCase={false}
        />
      )}
      {!shouldHideGoToMetasteps && (
        <Button
          classNames={{ root: cx(classes.buttons, classNames.goToMetasteps) }}
          href={routes.HOME}
          label={t('District.Menu.GoToMetasteps.Link')}
          variant="ghost"
          isUpperCase={false}
        />
      )}
    </div>
  );
};

LeftHeaderSection.propTypes = {
  classNames: PropTypes.shape({
    createSpace: PropTypes.string,
    explore: PropTypes.string,
    logo: PropTypes.string,
    root: PropTypes.string,
  }),
  hideOptions: PropTypes.shape({
    shouldHideCreateSpace: PropTypes.bool,
    shouldHideExplore: PropTypes.bool,
    shouldHideLearningCenter: PropTypes.bool,
    shouldHideLogo: PropTypes.bool,
  }),
  shouldShrinkLogo: PropTypes.bool,
};

export default LeftHeaderSection;
