const hasSubdomain = (hostname) => {
  const hostnameParts = hostname.split('.');

  const isDev = process.env.NODE_ENV === 'development';

  if ((isDev && hostnameParts.length === 2) || (!isDev && hostnameParts.length === 3)) {
    return true;
  }

  return false;
};

export const addSubdomainToHost = (subdomain) => {
  const { host: hostname, protocol } = window.location;

  return `${protocol}//${subdomain}.${hostname}`;
};

export const getSubdomain = (url = {}) => {
  const { host: hostname } = url;
  const { protocol } = url;

  if (hasSubdomain(hostname)) {
    const hostnameParts = hostname.split('.');
    const subdomain = hostnameParts[0];

    hostnameParts.shift();
    const domain = hostnameParts.join('.');

    return { subdomain, domain: `${protocol}//${domain}` };
  }

  return { domain: '' };
};
