import PropTypes from 'prop-types';
var propPropTypes = PropTypes.shape({
  addressable: PropTypes.shape({
    addressableId: PropTypes.number,
    catalogs: PropTypes.arrayOf(PropTypes.shape({
      catalogId: PropTypes.number,
      pathId: PropTypes.string,
      platform: PropTypes.number
    })),
    internalAddress: PropTypes.string
  }),
  createdAt: PropTypes.string,
  defaultSize: PropTypes.string,
  file: PropTypes.string,
  propId: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.number,
  updatedAt: PropTypes.string
});
export default propPropTypes;