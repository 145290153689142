var formatBytes = function formatBytes(bytes, decimals) {
  if (decimals === void 0) {
    decimals = 2;
  }
  if (!Number(bytes)) {
    return '0 Bytes';
  }
  var k = 1024;
  var dm = decimals < 0 ? 0 : decimals;
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  var i = Math.floor(Math.log(bytes) / Math.log(k));
  return "" + parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
};
export default formatBytes;