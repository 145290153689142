import { defaultTheme } from '@artsteps/components';

const defaultFontFamily = defaultTheme.other.fontFamily;

const downloadFontFamily = (fontFamily = defaultFontFamily) => {
  const fontUrl = `https://fonts.googleapis.com/css2?family=${fontFamily
    .split(',')[0]
    .replace(/[']/g, '')
    .replace(/ /g, '+')}`;
  const link = document.createElement('link');
  link.href = fontUrl;
  link.rel = 'stylesheet';
  document.head.appendChild(link);

  return link;
};

export default downloadFontFamily;
