import { ErrorBoundary } from '@artsteps/components';
import AuthProvider from '../../infrastructure/authentication/AuthProvider';
import { DistrictProvider } from '../../infrastructure/district-provider';
import { TranslationProvider } from '../../infrastructure/translation/TranslationProvider';
import { UserDataProvider } from '../../infrastructure/user-context';
import { ThemeProvider } from '../../infrastructure/theme-provider/ThemeProvider';
import Main from './Main';

const App = () => (
  <AuthProvider>
    <TranslationProvider language="en">
      <DistrictProvider>
        <UserDataProvider>
          <ThemeProvider>
            <ErrorBoundary>
              <Main />
            </ErrorBoundary>
          </ThemeProvider>
        </UserDataProvider>
      </DistrictProvider>
    </TranslationProvider>
  </AuthProvider>
);

export default App;
