export const unityBrokers = {
  settings: 'SettingsBroker',
  startup: 'StartupBroker',
  character: 'CharacterBroker',
  inspector: 'InspectorBroker',
  scene: 'SceneBroker',
  viewer: 'ViewerBroker',
  editor: {
    core: 'EditorBroker',
    construction: 'ConstructionBroker',
    content: 'ContentBroker',
    curation: 'CurationBroker',
    design: 'DesignBroker',
    guidedTour: 'GuidedTourBroker',
  },
};
