import { createContext, useContext, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { filesUrlsPropTypes } from '@artsteps/types';
import { Unity, useUnityContext } from 'react-unity-webgl';
import useUnityEventListener from './useUnityEventListener';
import { unityBrokers } from './unity-brokers/brokers';

const UnityContext = createContext();

const UnityProvider = ({ children, filesUrls, name = 'Player' }) => {
  const { isLoaded, unityProvider, sendMessage, addEventListener, removeEventListener } =
    useUnityContext({
      codeUrl: filesUrls.codeUrl,
      dataUrl: filesUrls.dataUrl,
      frameworkUrl: filesUrls.frameworkUrl,
      loaderUrl: filesUrls.loaderUrl,
      streamingAssetsUrl: filesUrls.streamingAssetsUrl,
      webglContextAttributes: {
        powerPreference: 2, // 'high-performance'
      },
    });

  const [isPlayerReadyForInput, setIsPlayerReadyForInput] = useState(false);

  useEffect(() => {
    if (isPlayerReadyForInput) {
      const handleFocus = (event) => {
        if (event.target.tagName === 'BUTTON') {
          return;
        }
        sendMessage(unityBrokers.settings, 'CaptureAllKeyboardInput', 0);
      };
      const handleBlur = (event) => {
        if (event.target.tagName === 'BUTTON') {
          return;
        }
        sendMessage(unityBrokers.settings, 'CaptureAllKeyboardInput', 1);
      };

      if (name !== 'Player') {
        document.addEventListener('focus', handleFocus, true);
        document.addEventListener('blur', handleBlur, true);
      }
    }
  });

  useEffect(() => {
    if (isLoaded && !filesUrls.loaderUrl.includes('Inspector')) {
      addEventListener(`${name}ReadyForInput`, () => setIsPlayerReadyForInput(true));
    }
  }, [isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const value = useMemo(
    () => ({
      isLoaded,
      isPlayerReadyForInput,
      sendMessage,
      addEventListener,
      removeEventListener,
      Unity,
      unityProvider,
    }),
    [
      isLoaded,
      isPlayerReadyForInput,
      sendMessage,
      unityProvider,
      addEventListener,
      removeEventListener,
    ],
  );

  return <UnityContext.Provider value={value}>{children}</UnityContext.Provider>;
};

const useUnity = () => {
  const context = useContext(UnityContext);
  if (!context) {
    throw new Error('useUnity must be used within a UnityProvider');
  }

  return context;
};

UnityProvider.propTypes = {
  children: PropTypes.node,
  filesUrls: filesUrlsPropTypes,
  name: PropTypes.string,
};

export { UnityProvider, useUnity, useUnityEventListener };
