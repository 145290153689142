const BASE_PATH = '/api/userService';

export const DISTRICT_PATH = `${BASE_PATH}/district`;
export const GROUP_PATH = `${BASE_PATH}/group`;
export const USER_PATH = `${BASE_PATH}/user`;

const userManagement = {
  districts: DISTRICT_PATH,
  district: (id) => `${DISTRICT_PATH}/${id}`,
  districtAccess: (subdomain) => `${DISTRICT_PATH}/districtAccess/${subdomain}`,
  districtFull: (id) => `${DISTRICT_PATH}/${id}/full`,
  districtInfoAuth: `${DISTRICT_PATH}/info/auth`,
  districtInfo: `${DISTRICT_PATH}/info`,
  districtAdmin: (id) => `${DISTRICT_PATH}/${id}/admin`,
  myDistricts: `${DISTRICT_PATH}/my`,
  myDistrictsAsMember: `${DISTRICT_PATH}/me`,
  districtMembers: (id) => `${DISTRICT_PATH}/${id}/members`,
  districtMember: (districtId, districtMemberId) => {
    if (districtId) {
      return districtMemberId
        ? `${DISTRICT_PATH}/${districtId}/member/${districtMemberId}`
        : `${DISTRICT_PATH}/${districtId}/member`;
    }
    return null;
  },
  districtSubdomainAvailable: (subdomain) => `${DISTRICT_PATH}/available/${subdomain}`,
  group: (id) => id && `${GROUP_PATH}/${id}`,
  groups: GROUP_PATH,
  districtGroups: (id) => `${GROUP_PATH}/district/${id}`,
  groupMembers: (id) => `${GROUP_PATH}/${id}/members`,
  myGroups: `${GROUP_PATH}/my`,
  myGroupsAsMember: `${GROUP_PATH}/me`,
  users: USER_PATH,
  myUser: `${USER_PATH}/my`,
  user: (id) => id && `${USER_PATH}/${id}`,
  userSearch: `${USER_PATH}/search`,
};

export default userManagement;
