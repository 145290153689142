/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useMemo } from 'react';

const usePublish = (eventName, args) => {
  const nativeEvent = useMemo(
    () =>
      new CustomEvent(eventName, {
        detail: args,
      }),
    [eventName, JSON.stringify(args)],
  );

  const publish = useCallback(
    (instanceEventName, callbackArgs) => {
      if (instanceEventName) {
        document.dispatchEvent(
          new CustomEvent(instanceEventName, {
            detail: callbackArgs,
          }),
        );
        return;
      }

      document.dispatchEvent(nativeEvent);
    },
    [eventName, JSON.stringify(args)],
  );

  return { publish, nativeEvent };
};

export default usePublish;
