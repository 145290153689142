var variantStyles = function variantStyles(theme) {
  return {
    bordered: {
      backgroundColor: '#F1F1F1',
      border: '1px solid #DCDCDC',
      borderRadius: theme.radius.md,
      '&:focus': {
        border: "1px solid " + theme.fn.primaryColor()
      },
      '&:focus-visible': {
        outline: 'none'
      }
    }
  };
};
export default variantStyles;