import JSZip from 'jszip';

export const asyncReadFile = (file) =>
  new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', (event) => {
      const text = event.target.result;
      resolve(text);
    });
    fileReader.readAsText(file);
  });

/**
 * Takes in a file, creates the blob url and returns only is hash
 * @param {File} file
 * @returns file's blobUrl's hash
 */
export const extractHashFromBlobUrl = (file) => {
  const fileBlobUrl = URL.createObjectURL(file);
  const urlOrigin = `blob:${window.location.origin}/`;
  const blobHashonly = fileBlobUrl.replace(urlOrigin, '');
  return blobHashonly;
};

/**
 * Takes a parent file and the relative files that are mentioned inside it.
 * Create a Blob URL from each relative file and keeps only the hash part of it
 * Then replaces the parent file's relative paths with the hashes of those relative files
 * @param {File} parentFile the initial parent File, use cases are OBJ or MTL
 * @param {Array[File]} relativeFiles the relative files, mentioned inside the parent file, to be replaced
 */
export const replaceFilePathsWithBlobs = async (parentFile, relativeFiles) => {
  const parentFileContent = await asyncReadFile(parentFile);

  const updatedParentFile = relativeFiles.reduce((acc, file) => {
    const blobHash = `${extractHashFromBlobUrl(file)}#file=${file.name}`;
    const filePath = ` .*${file.name}`;
    return acc.replaceAll(new RegExp(filePath, 'g'), ` ${blobHash}`);
  }, parentFileContent);

  return new File([updatedParentFile], parentFile.name);
};

const groupFiles = (droppedFiles) => {
  const groupedFiles = {
    zip: [],
    bin: [],
    glb: [],
    gltf: [],
    obj: [],
    mtl: [],
    rest: [],
  };

  droppedFiles.forEach((file) => {
    if (file.name.endsWith('.zip')) {
      groupedFiles.zip.push(file);
    } else if (file.name.endsWith('.bin')) {
      groupedFiles.bin.push(file);
    } else if (file.name.endsWith('.glb')) {
      groupedFiles.glb.push(file);
    } else if (file.name.endsWith('.obj')) {
      groupedFiles.obj.push(file);
    } else if (file.name.endsWith('.mtl')) {
      groupedFiles.mtl.push(file);
    } else if (file.name.endsWith('.gltf')) {
      groupedFiles.gltf.push(file);
    } else {
      groupedFiles.rest.push(file);
    }
  });

  return groupedFiles;
};

export const extractZipFiles = async (zipFile) => {
  const zip = new JSZip();
  const filesArray = [];

  try {
    const zipData = await zip.loadAsync(zipFile);
    const fileNames = Object.keys(zipData.files);

    for await (const fileName of fileNames) {
      const zipEntry = zipData.files[fileName];
      if (!zipEntry.dir) {
        const newFileName = fileName.split('/').pop();
        const fileBlob = await zipEntry.async('blob');
        const file = new File([fileBlob], newFileName);
        filesArray.push(file);
      }
    }

    return filesArray;
  } catch (error) {
    // TODO: Handle error cases
    // eslint-disable-next-line no-console
    console.error('Error reading ZIP file:', error);
  }

  return null;
};

export const validator = (droppedFiles, setFiles) => {
  const { zip, glb, obj, gltf } = groupFiles(droppedFiles);

  if (zip.length + glb.length + obj.length + gltf.length >= 2) {
    setFiles(null);
    throw new Error("Can't have more than 1 3D model files");
  }

  return null;
};

export const handleDropzoneDroppedFiles = async (droppedFiles) => {
  let {
    // eslint-disable-next-line prefer-const
    zip: zipFiles,
    glb: glbFiles,
    obj: objFiles,
    gltf: gltfFiles,
    mtl: mtlFiles,
    bin: binFiles,
    rest: textureFiles,
  } = groupFiles(droppedFiles);

  if (zipFiles.length >= 1) {
    const zipFile = zipFiles[0];
    const filesArray = await extractZipFiles(zipFile);
    const { glb, obj, gltf, mtl, bin, rest } = groupFiles(filesArray);

    glbFiles = [...glbFiles, ...glb];
    objFiles = [...objFiles, ...obj];
    gltfFiles = [...gltfFiles, ...gltf];
    mtlFiles = [...mtlFiles, ...mtl];
    binFiles = [...binFiles, ...bin];
    textureFiles = [...textureFiles, ...rest];
  }

  if (glbFiles.length >= 1) {
    return glbFiles[0];
  }

  if (objFiles.length >= 1) {
    const objFileContent = await asyncReadFile(objFiles[0]);

    const includedMtlFiles = mtlFiles.filter((mtlf) => objFileContent.includes(mtlf.name));

    if (includedMtlFiles.length === 0) {
      return objFiles[0];
    }

    const edittedMtlFiles = await Promise.all(
      includedMtlFiles.map(async (file) => await replaceFilePathsWithBlobs(file, textureFiles)),
    );

    const edittedObjFile = await replaceFilePathsWithBlobs(objFiles[0], edittedMtlFiles);

    return edittedObjFile;
  }

  if (gltfFiles.length >= 1) {
    const gltfFileContent = await asyncReadFile(gltfFiles[0]);
    const gltfFileJSON = JSON.parse(gltfFileContent);

    // making blobs for each of bin and texture files
    binFiles.forEach((binf) => {
      for (let i = 0; i < gltfFileJSON.buffers.length; i += 1) {
        if (gltfFileJSON.buffers[i].uri.includes(binf.name)) {
          gltfFileJSON.buffers[i].uri = `${extractHashFromBlobUrl(binf)}#file=${
            gltfFileJSON.buffers[i].uri
          } `;
          break;
        }
      }
    });

    textureFiles.forEach((texf) => {
      for (let i = 0; i < gltfFileJSON.images.length; i += 1) {
        if (gltfFileJSON.images[i].uri.includes(texf.name)) {
          gltfFileJSON.images[i].uri = `${extractHashFromBlobUrl(texf)}#file=${
            gltfFileJSON.images[i].uri
          }`;
          break;
        }
      }
    });

    const updatedGltfFileContent = JSON.stringify(gltfFileJSON);
    const updatedGltfFile = new File([updatedGltfFileContent], gltfFiles[0].name);
    return updatedGltfFile;
  }

  return null;
};
