import PropTypes from 'prop-types';
import useAxios from '../../../custom-hooks/useAxios';
import apis from '../../../server/endpoints/apis';
import { DistrictProvider } from '../components/DistrictProvider';
import { getSubdomain } from '../utils/subdomainUtils';

const DistrictProviderContainer = ({ children }) => {
  const urlLocation = window.location;
  const { subdomain, domain } = getSubdomain(urlLocation);

  const { data: districtData, loading } = useAxios({
    method: 'get',
    url: subdomain && apis.userManagement.districtAccess(subdomain),
  });

  if (subdomain && loading) {
    return null;
  }

  return (
    <DistrictProvider districtData={districtData} subdomain={subdomain} domain={domain}>
      {children}
    </DistrictProvider>
  );
};

DistrictProviderContainer.propTypes = {
  children: PropTypes.node,
};

export default DistrictProviderContainer;
