import { Route, Routes as RoutesComponent } from 'react-router-dom';
import { ErrorFallback } from '@artsteps/components';
import { useDistrict } from '../../infrastructure/district-provider';
import DeepLinkingTest from '../../pages/deep-linkin/DeepLinkingTest';
import ROUTES from '../../server/endpoints/routes';
import HomePage from '../../pages/home';
// import Editor from '../../pages/editor';
import Explore from '../../pages/explore';
// import Viewer from '../../pages/viewer';
// import AccountPage from '../../pages/account';
// import DistrictPage from '../../pages/district';
import UnityUploaderDevPage from '../../pages/dev/unity-uploader/UnityUploaderDevPage';
// import ResourcesContainer from '../../pages/resources';
import PricingPage from '../../pages/pricing';
// import BillingPage from '../../pages/billing';
import SettingsPage from '../../pages/settings/SettingsPage';
import Contact from '../../pages/contact';
import ComingSoonPage from '../../pages/coming-soon/components/ComingSoonPage';

const Content = (props) => {
  const { subdomain } = useDistrict();

  return (
    <RoutesComponent>
      <Route
        exact
        path={ROUTES.HOME}
        element={subdomain ? <ComingSoonPage {...props} /> : <HomePage {...props} />}
      />
      <Route exact path={ROUTES.CONTACT} element={<Contact {...props} />} />
      <Route exact path={ROUTES.EXPLORE} element={<Explore {...props} />} />
      <Route exact path={ROUTES.WALLET} element={<ComingSoonPage {...props} />} />
      <Route exact path={ROUTES.SETTINGS} element={<SettingsPage {...props} />} />
      <Route exact path={ROUTES.COMINGSOON} element={<ComingSoonPage {...props} />} />
      <Route exact path={`${ROUTES.EDITOR}/:sceneId`} element={<ComingSoonPage {...props} />} />
      <Route exact path={ROUTES.EDITOR} element={<ComingSoonPage {...props} />} />
      <Route exact path={`${ROUTES.VIEWER}/:sceneId`} element={<ComingSoonPage {...props} />} />
      <Route exact path={`${ROUTES.USER}/:userId`} element={<ComingSoonPage {...props} />} />
      <Route
        exact
        path={`${ROUTES.USER}/:userId/:category`}
        element={<ComingSoonPage {...props} />}
      />
      <Route
        exact
        path={`${ROUTES.USER}/:userId/:category/:subcategory`}
        element={<ComingSoonPage {...props} />}
      />
      <Route exact path="/:category" element={<ComingSoonPage {...props} />} />
      <Route exact path="/:category/:subcategory" element={<ComingSoonPage {...props} />} />
      <Route exact path={ROUTES.PRICING} element={<PricingPage {...props} />} />
      <Route exact path={ROUTES.RESOURCES.BASE} element={<ComingSoonPage {...props} />} />
      <Route
        exact
        path={`${ROUTES.RESOURCES.BASE}/:category`}
        element={<ComingSoonPage {...props} />}
      />
      <Route
        exact
        path={`${ROUTES.RESOURCES.BASE}/:category/:resourceId`}
        element={<ComingSoonPage {...props} />}
      />
      <Route
        exact
        path={`${ROUTES.RESOURCES}/:category/:resourceId`}
        element={<ComingSoonPage {...props} />}
      />
      <Route path="*" element={<ErrorFallback message="Page not found" isPageNotFound />} />

      {/* DEVELOPMENT ONLY ROUTES  */}
      <Route exact path={ROUTES.DEV_UNITY_UPLOADER} element={<UnityUploaderDevPage />} />
      <Route exact path={ROUTES.DEV_DEEP_LINK} element={<DeepLinkingTest />} />
    </RoutesComponent>
  );
};

export default Content;
