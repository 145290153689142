import { createStyles } from '@mantine/core';
import ComingSoon from '../../../common/coming-soon/ComingSoon';

const useStyles = createStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '32px 0',
  },
}));

const ComingSoonPage = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <ComingSoon />
    </div>
  );
};

export default ComingSoonPage;
