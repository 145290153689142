import { createStyles } from '@mantine/core';
import { Button } from '@artsteps/components';
import CustomLink from '../../infrastructure/subdomain-routing/CustomLink';

const useStyles = createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '8px',
  },
});

const DeepLinkingTest = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <h1>Deep linkin</h1>
      <Button href="metasteps://view/06510ae2-98ff-4aae-9c23-0b836c1c52fe">Button way</Button>
      <a href="metasteps://view/06510ae2-98ff-4aae-9c23-0b836c1c52fe">a href way</a>
      <CustomLink route="https://metasteps://view/06510ae2-98ff-4aae-9c23-0b836c1c52fe">
        NavLink way
      </CustomLink>
    </div>
  );
};

DeepLinkingTest.propTypes = {};

export default DeepLinkingTest;
