import KEYCODES from './keyPressCodes';
var preventEnterKeyDefaultBehavior = function preventEnterKeyDefaultBehavior(e) {
  // Get the code of pressed key
  var keyCode = e.which || e.keyCode;

  // 13 represents the Enter key
  if (keyCode === KEYCODES.ENTER && !e.shiftKey) {
    if (!e.repeat) {
      e.preventDefault();
    }
  }
};
export default preventEnterKeyDefaultBehavior;