import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useAxiosCallback } from '../../../custom-hooks/useAxios';
import apis from '../../../server/endpoints/apis';
import { UserDataProvider } from '../components/UserDataProvider';
import { getMetastepsRole } from '../utils/roles';

const UserDataContainer = ({ children }) => {
  const [userData, setUserData] = useState();
  const { accessTokenPayload } = useOidcAccessToken();
  const userId = accessTokenPayload?.sub;
  const { loading, executeRequest: fetchUserData } = useAxiosCallback({
    method: 'get',
    url: apis.userManagement.myUser,
  });
  const { executeRequest: createUser } = useAxiosCallback({
    method: 'post',
    url: apis.userManagement.users,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const userRole = getMetastepsRole(accessTokenPayload);

  useEffect(() => {
    const onUserDoesNotExist = (error) => {
      if (error.response.status === 302) {
        createUser({ body: { name: accessTokenPayload.name }, onSuccess: setUserData });
      }
    };

    if (userId) {
      fetchUserData({
        onSuccess: setUserData,
        onError: onUserDoesNotExist,
      });
    }
  }, [accessTokenPayload?.name, createUser, fetchUserData, userId]);

  return (
    <UserDataProvider
      isLoading={loading}
      userData={userData}
      setUserData={setUserData}
      userId={userId}
      role={userRole}
    >
      {children}
    </UserDataProvider>
  );
};

UserDataContainer.propTypes = {
  children: PropTypes.node,
};

export default UserDataContainer;
