import { useTranslation } from '../../../../infrastructure/translation/TranslationProvider';
import VisibilityToggle from './VisibilityToggle';

const HideContentLibrary = () => {
  const { t } = useTranslation();

  const handleChange = () => {};

  return (
    <VisibilityToggle
      title={t('SettingsPage.VisibilityTab.HideContentLibrary.Title')}
      description={t('SettingsPage.VisibilityTab.HideContentLibrary.Description')}
      isChecked={false}
      onChange={handleChange}
    />
  );
};

export default HideContentLibrary;
