import { useLocation } from 'react-router-dom';
import { createStyles } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { Header, screenSizes, BurgerButton, mediaQueries } from '@artsteps/components';
import { useDistrict } from '../../infrastructure/district-provider';
import ROUTES from '../../server/endpoints/routes';
import LeftHeaderSection from '../../common/header/LeftHeaderSection';
import UserSection from '../../common/header/user/UserSection';
import MainHeaderSection from '../../common/header/MainHeaderSection';
import HeaderBurgerMenu from '../../common/header/HeaderBurgerMenu';

const useStyles = createStyles((theme, { isInHomepage }) => ({
  header: {
    position: 'relative',
    backgroundColor: isInHomepage ? 'transparent' : theme.colors.typography[7],
    pointerEvents: 'auto',
    height: '120px',
    alignItems: 'center',

    [mediaQueries.medium.down]: {
      height: '60px',
    },
  },
  headerSection: {
    width: '100%',
    pointerEvents: 'auto',
  },
  burgerButton: {
    position: 'absolute',
    left: '4px',
  },
}));

const HeaderComponent = () => {
  const [isBurgerMenuOpen, { toggle: toggleBurgerMenu, close: closeBurgerMenu }] =
    useDisclosure(false);
  const { pathname } = useLocation();
  // const isInside3D = pathname.includes(ROUTES.EDITOR) || pathname.includes(ROUTES.VIEWER);
  const isInHomepage = Boolean(pathname === ROUTES.HOME || pathname === ROUTES.EXPLORE);
  // const shouldHideCreateSpace = useMediaQuery(`(max-width: ${screenSizes.medium.max})`);
  const shouldShowBurgerMenu = useMediaQuery(`(max-width: ${screenSizes.small.max})`);
  const { districtData } = useDistrict();
  const { classes } = useStyles({ isInHomepage });

  // if (isInside3D) {
  //   return null;
  // }

  return (
    <Header className={classes.header}>
      <LeftHeaderSection
        hideOptions={{
          shouldHideCreateSpace: true,
          shouldHideResources: Boolean(districtData?.districtId),
          shouldHideGoToMetasteps: !districtData?.districtId,
        }}
      />
      {!shouldShowBurgerMenu ? (
        <MainHeaderSection
          hideOptions={{
            shouldHideExplore: Boolean(districtData?.districtId),
            shouldHidePricing: Boolean(districtData?.districtId),
            shouldHideResources: Boolean(districtData?.districtId),
          }}
        />
      ) : (
        <>
          <BurgerButton
            className={classes.burgerButton}
            isOpen={isBurgerMenuOpen}
            onClick={toggleBurgerMenu}
          />
          {isBurgerMenuOpen && <HeaderBurgerMenu onClose={closeBurgerMenu} />}
        </>
      )}
      <UserSection />
    </Header>
  );
};

export default HeaderComponent;
