import { useDistrict } from '../../infrastructure/district-provider';
import ROUTES from '../../server/endpoints/routes';

const useRoutes = () => {
  const { domain } = useDistrict();

  const turnToAbsolute = (route) => `${domain}${route}`;

  return {
    routes: {
      HOME: turnToAbsolute(ROUTES.HOME),
      EXPLORE: ROUTES.EXPLORE,
      SETTINGS: turnToAbsolute(ROUTES.SETTINGS),
      WALLET: turnToAbsolute(ROUTES.WALLET),
      EDITOR: ROUTES.EDITOR,
      VIEWER: ROUTES.VIEWER,
      USER: turnToAbsolute(ROUTES.USER),
      RESOURCES: turnToAbsolute(ROUTES.RESOURCES.BASE),
      PRICING: turnToAbsolute(ROUTES.PRICING),
    },
  };
};

export default useRoutes;
