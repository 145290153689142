import axios from 'axios';
import { deepTransformObjects } from '../../utils/transformObjects';
import { toCamel } from '../../utils/camelPascalTransformation';
import { SCENE_PATH } from '../endpoints/services/sceneManagement';

export const setupAxiosInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      const {
        data,
        config: { url },
      } = response;

      const shouldApplyCamelCase = Boolean(data) && url.startsWith(`${SCENE_PATH}/`);

      if (shouldApplyCamelCase) {
        return { ...response, data: deepTransformObjects(data, toCamel) };
      }
      return response;
    },
    // eslint-disable-next-line promise/no-promise-in-callback
    (error) => Promise.reject(error),
  );
};
