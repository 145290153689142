import { createStyles } from '@mantine/core';
import { mediaQueries } from '@artsteps/components';
import { useTranslation } from '../../infrastructure/translation/TranslationProvider';

const useStyles = createStyles((theme) => ({
  copyright: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '10px',
    letterSpacing: '0.65px',
    color: theme.colors.typography[0],
    textTransform: 'uppercase',
    whiteSpace: 'pre-wrap',

    [mediaQueries.xsmall.only]: {
      marginTop: '16px',
      flexDirection: 'column',
    },
  },
}));

const Copyright = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const copyrightLabel = '2024 Metasteps LTD';
  const allRightsReserved = t('Footer.Copyright.Text');

  return (
    <div className={classes.copyright}>
      <span>{copyrightLabel}</span>
      <span>{allRightsReserved}</span>
    </div>
  );
};

export default Copyright;
