import { useMediaQuery } from '@mantine/hooks';
export default function useBreakpoints(theme) {
  var breakpoints = {
    query: {
      up: function up(min) {
        return "@media screen and (min-width: " + min + ")";
      },
      only: function only(min, max) {
        return "@media screen and (min-width: " + min + ") and (max-width: " + max + ")";
      },
      down: function down(max) {
        return "@media screen and (max-width: " + max + ")";
      }
    },
    is: {
      xs: useMediaQuery("(max-width: " + theme.breakpoints.xs + ")"),
      sm: useMediaQuery("(min-width: " + (theme.breakpoints.xs + 1) + ") and (max-width: " + theme.breakpoints.sm + ")"),
      md: useMediaQuery("(min-width: " + (theme.breakpoints.sm + 1) + ") and (max-width: " + theme.breakpoints.md + ")"),
      lg: useMediaQuery("(min-width: " + (theme.breakpoints.md + 1) + ") and (max-width: " + theme.breakpoints.lg + ")"),
      xl: useMediaQuery("(min-width: " + (theme.breakpoints.lg + 1) + ")")
    },
    largerThan: {
      xs: useMediaQuery("(min-width: " + theme.breakpoints.xs + ")"),
      sm: useMediaQuery("(min-width: " + theme.breakpoints.sm + ")"),
      md: useMediaQuery("(min-width: " + theme.breakpoints.md + ")"),
      lg: useMediaQuery("(min-width: " + theme.breakpoints.lg + ")"),
      xl: useMediaQuery("(min-width: " + theme.breakpoints.xl + ")")
    },
    smallerThan: {
      xs: useMediaQuery("(max-width: " + theme.breakpoints.xs + ")"),
      sm: useMediaQuery("(max-width: " + theme.breakpoints.sm + ")"),
      md: useMediaQuery("(max-width: " + theme.breakpoints.md + ")"),
      lg: useMediaQuery("(max-width: " + theme.breakpoints.lg + ")"),
      xl: useMediaQuery("(max-width: " + theme.breakpoints.xl + ")")
    },
    active: 'xs'
  };
  if (breakpoints.is.xs) {
    breakpoints.active = 'xs';
  }
  if (breakpoints.is.sm) {
    breakpoints.active = 'sm';
  }
  if (breakpoints.is.md) {
    breakpoints.active = 'md';
  }
  if (breakpoints.is.lg) {
    breakpoints.active = 'lg';
  }
  if (breakpoints.is.xl) {
    breakpoints.active = 'xl';
  }
  return breakpoints;
}