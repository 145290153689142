import KEYCODES from './keyPressCodes';
var filterKeyEnter = function filterKeyEnter(handler) {
  return function (e) {
    if (e.keyCode === KEYCODES.ENTER && typeof handler === 'function') {
      handler(e);
    }
  };
};
var accessibleOnInteraction = function accessibleOnInteraction(onAction, options) {
  if (options === void 0) {
    options = {
      click: true,
      enter: false
    };
  }
  return typeof onAction === 'function' ? Object.assign({
    role: 'button',
    tabIndex: 0
  }, options.enter && {
    onKeyDown: filterKeyEnter(onAction)
  }, options.click && {
    onClick: onAction
  }) : {};
};
export default accessibleOnInteraction;