import { createStyles } from '@mantine/core';
import { Button } from '@artsteps/components';
import HomepageVideo from '@artsteps/assets/videos/homepage-video.mp4';
import HomepageVideoThumbnail from '@artsteps/assets/images/Homepage-Video-Thumbnail.png';
import { Form, TextInput } from '@artsteps/form';
import { useTranslation } from '../../infrastructure/translation/TranslationProvider';

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: '880px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  greenText: {
    color: theme.fn.primaryColor(),
  },
  title: {
    fontSize: '44px',
    lineHeight: 1,
    fontWeight: '400',
    letterSpacing: '1.56px',
  },
  description: {
    marginTop: '24px',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: 0,
  },
  videoWrapper: {
    marginTop: '32px',
    borderRadius: '16px',
    overflow: 'hidden',
    width: '100%',
    height: '432px',
  },
  video: {
    objectFit: 'cover',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  footer: {
    marginTop: '32px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%',
    padding: '0 16px',
    gap: '32px',
  },
  button: {
    width: '180px',
    height: '56px',
  },
  buttonLabel: {
    fontSize: '20px',
    lineHeight: 1,
    letterSpacing: '1.78px',
    gap: '16px',
  },
  label: {
    marginTop: '24px',
    fontSize: '24px',
    lineHeight: 1,
    letterSpacing: '1px',
    fontWeight: '300',
  },
  inputWrapper: {
    flexGrow: 1,
  },
  input: {
    color: 'black',
    border: `2px solid ${theme.fn.primaryColor()}`,
    height: '63px',
    borderRadius: '4px',
    fontSize: '19px',
    lineHeight: '32px',
    letterSpacing: '1.14px',
    '::placeholder': {
      color: 'black',
      fontSize: '19px',
      lineHeight: '32px',
      letterSpacing: '1.14px',
    },
  },
}));

const ComingSoon = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const handleNotifyMe = (data) => {
    // TODO: handle the notify me email address

    const email = 'support@metasteps.com';
    const subject = `Notify%20${data.email}`;
    window.location.href = `mailto:${email}?subject=${subject}`;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        {t('ComingSoonPage.Title.SomethingAwesome')}
        <span className={classes.greenText}>{t('ComingSoonPage.Title.IsCreated')}</span>
      </div>
      <div className={classes.description}>{t('ComingSoonPage.Description')}</div>
      <div className={classes.videoWrapper}>
        <video
          className={classes.video}
          data-testid="video-player"
          controls
          src={HomepageVideo}
          controlsList="nodownload noremoteplayback"
          preload="metadata"
          loading="lazy"
          poster={HomepageVideoThumbnail}
        >
          <track kind="captions" />
        </video>
      </div>
      <div className={classes.label}>{t('ComingSoonPage.Label')}</div>
      <Form className={classes.footer} onSubmit={handleNotifyMe}>
        <TextInput
          name="email"
          placeholder={t('ComingSoonPage.Email.Placeholder')}
          validationRules={{
            required: t('ComingSoonPage.Email.Required'),
            validate: (text) => {
              const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              if (!pattern.test(text)) {
                return t('ComingSoonPage.Email.Valid');
              }
              return true;
            },
          }}
          classNames={{ wrapper: classes.inputWrapper, input: classes.input }}
        />
        <Button
          variant="gradient"
          isUpperCase={false}
          type="submit"
          classNames={{ root: classes.button, label: classes.buttonLabel }}
        >
          {t('ComingSoonPage.NotifyMeButton.Label')}
        </Button>
      </Form>
    </div>
  );
};

export default ComingSoon;
