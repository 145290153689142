export var getFormatOptions = function getFormatOptions(shouldShowAlpha) {
  if (shouldShowAlpha === void 0) {
    shouldShowAlpha = false;
  }
  return [{
    value: 'hexa',
    label: shouldShowAlpha ? 'HEXA' : 'HEX'
  }, {
    value: 'rgba',
    label: shouldShowAlpha ? 'RGBA' : 'RGB'
  }, {
    value: 'hsla',
    label: shouldShowAlpha ? 'HSLA' : 'HSL'
  }];
};