import { useCallback, useState } from 'react';
var usePagination = function usePagination(_temp) {
  var _ref = _temp === void 0 ? {} : _temp,
    _ref$currentPage = _ref.currentPage,
    currentPage = _ref$currentPage === void 0 ? 0 : _ref$currentPage,
    _ref$itemsPerPage = _ref.itemsPerPage,
    itemsPerPage = _ref$itemsPerPage === void 0 ? 9 : _ref$itemsPerPage;
  var _useState = useState(currentPage),
    page = _useState[0],
    setPage = _useState[1];
  var goToPage = useCallback(function (pageTarget) {
    setPage(pageTarget);
  }, [setPage]);
  var goToNext = useCallback(function () {
    setPage(function (p) {
      return p + 1;
    });
  }, [setPage]);
  var goToPrevious = useCallback(function () {
    setPage(function (p) {
      return p - 1;
    });
  }, [setPage]);
  return {
    page: page,
    itemsPerPage: itemsPerPage,
    goToPage: goToPage,
    goToNext: goToNext,
    goToPrevious: goToPrevious
  };
};
export default usePagination;