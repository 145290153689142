export const ASSET_FIELDS = {
  file: 'file',
  assetFile: 'assetFile',
  thumbnail: 'thumbnail',
  assetThumbnail: 'assetThumbnail',
  title: 'title',
  description: 'description',
  interactive: 'interactive',
  collisions: 'collisions',
  transparent: 'transparent',
  defaultSize: 'defaultSize',
  tags: 'tags',
  licenseId: 'licenseId',
  audioFile: 'audioFile',
  audio: 'audio',
  imposter: 'imposter',
  imposterFile: 'imposterFile',
  frame: 'frame',
  frameId: 'frameId',
  surfaceType: 'surfaceType',
  provider: 'provider',
  content: 'content',
  fontSize: 'fontSize',
  fontStyles: 'fontStyles',
  fontTransform: 'fontTransform',
  colorTint: 'colorTint',
  dropShadow: 'dropShadow',
  outlineColor: 'outlineColor',
  outlineWidth: 'outlineWidth',
  verticalAlignment: 'verticalAlignment',
  horizontalAlignment: 'horizontalAlignment',
  requiredFonts: 'requiredFonts',
  hasBacking: 'hasBacking',
  backingTint: 'backingTint',
  popUp: 'popUp',
  autoplay: 'autoplay',
  videoLoop: 'videoLoop',
  animationClip: 'animationClip',
  animated: 'animated',
  artifactDescription: 'artifactDescription',
};

export const PICTURE_DEFAULTS = {
  file: undefined,
  assetFile: undefined,
  thumbnail: undefined,
  assetThumbnail: undefined,
  title: '',
  description: '',
  interactive: true,
  collisions: true,
  transparent: false,
  defaultSize: undefined,
  tags: undefined,
  licenseId: undefined,
  audioFile: undefined,
  audio: undefined,
  hasBacking: true,
  backingTint: '#FFFFFF00',
  frame: undefined,
  provider: 'Source',
};

export const VIDEO_DEFAULTS = {
  file: undefined,
  assetFile: undefined,
  thumbnail: undefined,
  assetThumbnail: undefined,
  title: '',
  description: '',
  interactive: true,
  collisions: undefined,
  defaultSize: undefined,
  tags: undefined,
  licenseId: undefined,
  autoplay: false,
  isPortrait: false,
  provider: 'Source',
  popUp: false,
  videoLoop: false,
};

export const MODEL_DEFAULTS = {
  file: undefined,
  assetFile: undefined,
  assetThumbnail: undefined,
  imposterFile: undefined,
  imposter: undefined,
  title: '',
  description: '',
  interactive: true,
  collisions: undefined,
  defaultSize: undefined,
  tags: undefined,
  licenseId: undefined,
  audioFile: undefined,
  audio: undefined,
  animationClip: undefined,
  animated: false,
  provider: 'Source',
  format: 'GLTF',
};

export const TEXT_DEFAULTS = {
  content: '',
  fontSize: 60,
  fontStyles: [],
  fontTransform: undefined,
  colorTint: '#000000',
  verticalAlignment: 'middle',
  horizontalAlignment: 'center',
  outlineColor: '#000000',
  outlineWidth: 0,
  dropShadow: false,
  requiredFonts: [],
  title: '',
  description: '',
  interactive: true,
  collisions: undefined,
  defaultSize: undefined,
  licenseId: undefined,
  provider: 'Source',
};

export const IMAGE_MAX_FILESIZE = 4 * 1024 ** 2;

export const IMAGE_ASSET_SUPPORTED_FORMATS = {
  extensions: ['.jpg', '.jpeg', '.png', '.jfif', '.jfi'],
  mimeTypes: ['image/jpeg', 'image/png'],
};

export const IMAGE_UPLOADER_OPTIONS = [
  { label: 'Global.AssetUpload.Dropdown.UploadFile.Label', value: 'uploadFile' },
  { label: 'Global.AssetUpload.Dropdown.Flickr.Label', value: 'flickr' },
];

export const VIDEO_MAX_FILESIZE = 4 * 1024 ** 2;

export const VIDEO_ASSET_SUPPORTED_FORMATS = {
  extensions: ['.mp4', '.webm', '.ogg'],
  mimeTypes: ['video/mp4', 'video/webm', 'video/ogg'],
};

export const VIDEO_UPLOADER_OPTIONS = [
  { label: 'Global.AssetUpload.Dropdown.UploadFile.Label', value: 'uploadFile' },
  { label: 'Global.AssetUpload.Dropdown.YouTube.Label', value: 'youtube' },
];

export const MODEL_MAX_FILESIZE = 4 * 1024 ** 2;

export const MODEL_ASSET_SUPPORTED_FORMATS = {
  extensions: ['.jpg', '.jpeg', '.png', '.glb', '.gltf', '.obj', '.bin', '.mtl'],
  mimeTypes: {
    'application/octet-stream': ['.bin', '.glb', '.obj', '.gltf'],
    'application/x-tgif': ['.obj'],
    'model/mtl': ['.mtl'],
    'model/obj': ['.obj'],
    'model/gltf+json': ['.gltf', '.glb'],
    'model/gltf-binary': ['.gltf', '.glb'],
    'application/zip': ['.zip'],
    'image/*': [],
  },
};

export const MODEL_UPLOADER_OPTIONS = [
  { label: 'Global.AssetUpload.Dropdown.UploadFile.Label', value: 'uploadFile' },
  // { label: 'Global.AssetUpload.Dropdown.Url.Label', value: 'url' },
];

export const AUDIO_MAX_FILESIZE = 4 * 1024 ** 2;

export const AUDIO_SUPPORTED_FORMATS = {
  extensions: ['.mp3', '.wav', '.ogg'],
  mimeTypes: ['audio/mpeg', 'audio/wav', 'audio/ogg'],
};

export const AUDIO_UPLOADER_OPTIONS = [
  { label: 'Global.AssetUpload.Dropdown.UploadFile.Label', value: 'uploadFile' },
  // { label: 'Global.AssetUpload.Dropdown.Url.Label', value: 'url' },
];
