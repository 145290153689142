export const base64toBlob = (base64Data, contentType = '') => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  // const byteCharacters = Buffer.from(base64Data, 'base64').toString('latin1');
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex += 1) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; i += 1, offset += 1) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const convertFileFromBase64 = (base64String, fileName, type) => {
  if (base64String) {
    const blob = base64toBlob(base64String, type);
    const file = new File([blob], fileName, { type });
    return file;
  }
  return undefined;
};
