import PropTypes from 'prop-types';
import { createStyles } from '@mantine/core';
import { Toggle } from '@artsteps/components';
import { useTranslation } from '../../../../infrastructure/translation/TranslationProvider';

const useStyles = createStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '500px',
  },
  upper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.17px',
    fontWeight: 'bold',
  },
  description: {
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.17px',
  },
}));

const VisibilityToggle = ({ title, description, isChecked, onChange, isDisabled }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.upper}>
        <div className={classes.title}>{title}</div>
        <Toggle
          onLabel={t('Global.Yes.Label')}
          offLabel={t('Global.No.Label')}
          shouldShowError={false}
          size="xl"
          defaultValue={isChecked}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      </div>
      <div className={classes.description}>{description}</div>
    </div>
  );
};

VisibilityToggle.propTypes = {
  description: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
};

export default VisibilityToggle;
