/**
 * Converts a single numeric component to its hexadecimal representation.
 *
 * @param {number} c - The numeric component to convert.
 * @returns {string} The hexadecimal representation of the numeric component.
 *
 * This function takes a numeric component (typically an integer between 0 and 255),
 * converts it to its hexadecimal equivalent, and returns it as a string.
 * If the resulting hexadecimal string is only one character long, it adds a leading
 * '0' to ensure a two-character format is returned.
 */
export var componentToHex = function componentToHex(c) {
  var number = Number(c);
  if (typeof c === 'undefined' || c === null || Number.isNaN(number)) {
    return undefined;
  }
  var adjustedNumber;
  if (number < 0) {
    adjustedNumber = 0;
  } else if (number > 255) {
    adjustedNumber = 255;
  } else {
    adjustedNumber = number;
  }
  var hex = Math.round(adjustedNumber).toString(16).toUpperCase();
  return hex.length === 1 ? "0" + hex : hex;
};

/**
 * Checks if a given string represents a valid two-digit hexadecimal number.
 *
 * @param {string} str - The input string to be checked.
 * @returns {boolean} Returns true if the input string is a valid two-digit hexadecimal number, otherwise returns false.
 */
export var isTwoDigitHexademical = function isTwoDigitHexademical(str) {
  var hexPattern = /^[0-9A-Fa-f]{2}$/;
  return hexPattern.test(str);
};

/**
 * Checks if a given string represents a valid hexadecimal color.
 *
 * @param {string} str - The input string to be checked for hexadecimal color validity.
 * @returns {boolean} - Returns true if the input string is a valid hexadecimal color
 *                     with lengths of 3, 6, or 8 characters (with or without '#' prefix),
 *                     otherwise returns false.
 */
export var isHexademicalColor = function isHexademicalColor(str) {
  // const hexPattern = /^#?([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/;
  var hexPattern = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/;
  return hexPattern.test(str);
};

/**
 * Checks if a given string represents a valid RGB or RGBA color.
 *
 * @param {string} str - The input string to be checked for RGB or RGBA color validity.
 * @returns {boolean} - Returns true if the input string is a valid RGB or RGBA color,
 *                     otherwise returns false.
 */
export var isRGBColor = function isRGBColor(rgbString) {
  if (typeof rgbString !== 'string') {
    return false;
  }
  var rgbLowerCase = rgbString.toLowerCase();
  var isRgba = rgbLowerCase.startsWith('rgba');
  var isRgb = rgbLowerCase.startsWith('rgb');
  var range = '(\\d|[1-9]\\d|1\\d{2}|2[0-4]\\d|2[0-5]{2})';
  var alpha = '([01]|0?\\.\\d+)';
  var rgbPattern = new RegExp("^(?:rgb\\(\\s*" + range + "\\s*,\\s*" + range + "\\s*,\\s*" + range + "\\s*\\)|rgba\\(\\s*" + range + "\\s*,\\s*" + range + "\\s*,\\s*" + range + "\\s*,\\s*" + alpha + "\\s*\\))$");
  return isRgb && rgbPattern.test(rgbLowerCase) || isRgba && rgbPattern.test(rgbLowerCase);
};

/**
 * Checks if a given string is a valid HSL or HSLA CSS color value.
 *
 * @param {string} hslValue - The input string to be validated.
 *
 * @returns {boolean} - True if the input is a valid HSL or HSLA color value, false otherwise.
 */
export var isHslColor = function isHslColor(hslValue) {
  if (typeof hslValue !== 'string') {
    return false;
  }
  var hslLowerCase = hslValue.toLowerCase();
  if (hslLowerCase.startsWith('hsl(') || hslLowerCase.startsWith('hsla(')) {
    if (hslLowerCase.endsWith(')')) {
      var values = hslLowerCase.slice(hslValue.indexOf('(') + 1, -1).split(',');

      // Check if there are either 3 or 4 values
      if (hslLowerCase.startsWith('hsl(') && values.length === 3 || hslLowerCase.startsWith('hsla(') && values.length === 4) {
        // Check if the hue, saturation, and lightness are valid numbers
        var h = parseFloat(values[0]);
        var s = parseFloat(values[1]);
        var l = parseFloat(values[2]);
        if (!Number.isNaN(h) && !Number.isNaN(s) && !Number.isNaN(l)) {
          // Check if the hue is within [0, 360]
          if (h >= 0 && h <= 360) {
            // Check if the saturation and lightness are within [0, 100]
            if (s >= 0 && s <= 100 && l >= 0 && l <= 100) {
              // If there are 4 values, check if the alpha is a valid number within [0, 1]
              if (values.length === 4) {
                var alpha = parseFloat(values[3]);
                if (!Number.isNaN(alpha) && alpha >= 0 && alpha <= 1) {
                  return true;
                }
              } else {
                return true;
              }
            }
          }
        }
      }
    }
  }
  return false;
};

/**
 * Converts a decimal value representing an alpha (opacity) channel to its hexadecimal equivalent.
 *
 * @param {number} alpha - A decimal value between 0 and 1 representing opacity.
 * @returns {string|undefined} The hexadecimal representation of the alpha channel (between '00' and 'FF'),
 *                            or undefined if the input alpha value is greater than 1.
 */
export var decimalToHex = function decimalToHex(alpha) {
  if (alpha > 1 || alpha < 0) {
    return undefined;
  }
  return componentToHex(Math.round(alpha * 255));
};

/**
 * Removes the alpha channel from a color string based on the specified format.
 *
 * @param {string} format - The desired color format ('hex', 'hexa', 'rgb', 'rgba', 'hsl', 'hsla').
 * @param {string} color - The color string to process.
 * @returns {string} - The modified color string with alpha channel removed.
 */
export var removeAlpha = function removeAlpha(format, color) {
  if (color === void 0) {
    color = '';
  }
  var colorString = color.toLowerCase();
  var formatString = format.toLowerCase();

  // Regular expressions for matching alpha components in different formats
  var alphaHexRegex = /#([a-fA-F\d]{6})[a-fA-F\d]{2}$/i;
  var alphaRGBRegex = /rgba\((\d+),\s*(\d+),\s*(\d+),\s*1\)$/i;
  var alphaHSLRegex = /hsla\((\d+),\s*(\d+)%,\s*(\d+)%,\s*1\)$/i;

  // Remove alpha channel based on the provided format
  switch (formatString) {
    case 'hex':
    case 'hexa':
      if (alphaHexRegex.test(colorString) && formatString === 'hexa') {
        return colorString.replace(alphaHexRegex, '#$1');
      }
      break;
    case 'rgb':
    case 'rgba':
      if (alphaRGBRegex.test(colorString) && formatString === 'rgba') {
        return colorString.replace(alphaRGBRegex, 'rgb($1, $2, $3)');
      }
      break;
    case 'hsl':
    case 'hsla':
      if (alphaHSLRegex.test(colorString) && formatString === 'hsla') {
        return colorString.replace(alphaHSLRegex, 'hsl($1, $2%, $3%)');
      }
      break;
    default:
      // Invalid format, return the original string
      return colorString;
  }
  return colorString;
};

/**
 * Adds an alpha value to a color string based on the specified format.
 *
 * @param {string} format - The format to apply to the color string ('hex', 'hexa', 'rgb', 'rgba', 'hsl', 'hsla').
 * @param {string} color - The color string to which the alpha value should be added.
 * @returns {string} - The color string with the alpha value applied as per the format rules.
 */
export var addAlpha = function addAlpha(formatString, color) {
  if (color === void 0) {
    color = '';
  }
  var format = formatString.toLowerCase();
  if (['hex', 'hexa', 'rgb', 'rgba', 'hsl', 'hsla'].indexOf(format) === -1) {
    return color;
  }
  var hasAlpha;
  var resultColor = color;
  switch (format) {
    case 'hex' || 'hexa':
      hasAlpha = color.length === 9;
      if (!hasAlpha) {
        resultColor = color + "FF";
      }
      break;
    case 'rgb' || 'rgba':
      hasAlpha = color.startsWith('rgba');
      if (!hasAlpha) {
        resultColor = "rgba" + color.slice(3, -1) + ", 1)";
      }
      break;
    case 'hsl' || 'hsla':
      hasAlpha = color.startsWith('hsla');
      if (!hasAlpha) {
        resultColor = "hsla" + color.slice(3, -1) + ", 1)";
      }
      break;
    default:
      break;
  }
  return resultColor; // If the format and alpha are already correct
};

/**
 * Converts a two-digit hexadecimal string representing an alpha channel (opacity) value
 * to its equivalent decimal value, normalized between 0 and 1.
 *
 * @param {string} hex - A two-digit hexadecimal string (e.g., "ff") representing opacity.
 *
 * @returns {number|undefined} - The decimal representation of the alpha channel if the input
 *                              is a valid two-digit hexadecimal string; otherwise, returns undefined.
 */
export var hexToAlphaDecimal = function hexToAlphaDecimal(hex) {
  if (!isTwoDigitHexademical(hex)) {
    return undefined;
  }
  var number = parseInt(hex, 16);
  return parseFloat((number / 255).toFixed(2));
};
export var hexToDecimal = function hexToDecimal(hex) {
  return parseInt(hex, 16);
};
export var getColorFormat = function getColorFormat(color) {
  if (isHexademicalColor(color)) {
    return 'HEXA';
  }
  if (isRGBColor(color)) {
    return 'RBGA';
  }
  if (isHslColor(color)) {
    return 'HSLA';
  }
  return undefined;
};
export default {
  componentToHex: componentToHex,
  isTwoDigitHexademical: isTwoDigitHexademical,
  isHexademicalColor: isHexademicalColor,
  isRGBColor: isRGBColor,
  isHslColor: isHslColor,
  decimalToHex: decimalToHex,
  removeAlpha: removeAlpha,
  addAlpha: addAlpha,
  hexToAlphaDecimal: hexToAlphaDecimal,
  getColorFormat: getColorFormat
};