const BASE_PATH = 'https://models.readyplayer.me';

const model = (avatarId) => `${BASE_PATH}/${avatarId}.glb`;
const thumbnail = (avatarId) =>
  `${BASE_PATH}/${avatarId}.png?scene=fullbody-posture-v1-transparent`;

export default {
  model,
  thumbnail,
};
