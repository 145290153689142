import DOMPurify from 'dompurify';
var convertOembedToIframe = function convertOembedToIframe(html) {
  var tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  var oembeds = tempDiv.querySelectorAll('oembed[url]');
  oembeds.forEach(function (oembed) {
    var url = oembed.getAttribute('url');
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      var iframe = document.createElement('iframe');
      iframe.setAttribute('width', '100%');
      iframe.setAttribute('height', '315');
      iframe.setAttribute('src', url.replace('youtu.be/', 'www.youtube.com/embed/').replace('watch?v=', 'embed/'));
      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share');
      iframe.setAttribute('allowfullscreen', '');
      oembed.parentNode.replaceChild(iframe, oembed);
    }
  });
  return tempDiv.innerHTML;
};
var sanitizeContent = function sanitizeContent(dirtyHtml) {
  var convertedHtml = convertOembedToIframe(dirtyHtml);
  var purifyConfig = {
    ADD_TAGS: ['iframe', 'oembed'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'style']
  };
  return DOMPurify.sanitize(convertedHtml, purifyConfig);
};
export var innerHtml = function innerHtml(content) {
  return {
    dangerouslySetInnerHTML: {
      __html: sanitizeContent(content)
    }
  };
};
export default sanitizeContent;