import { URL_ORIGIN } from '../../../constants/url';

const BASE_PATH = '/api/fileService';

const fileManagement = {
  baseUrl: `${URL_ORIGIN}${BASE_PATH}`,
  file: (id) => id && `${URL_ORIGIN}${BASE_PATH}/file/${id}`,
  thumbnail: (id) => id && `${URL_ORIGIN}${BASE_PATH}/thumbnail/${id}`,
};

export default fileManagement;
