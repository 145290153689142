import PropTypes from 'prop-types';
var slotPropTypes = PropTypes.shape({
  active: PropTypes.bool,
  createdAt: PropTypes.string,
  districtId: PropTypes.string,
  districtSlot: PropTypes.bool,
  districtSubdomain: PropTypes.string,
  ownerId: PropTypes.string,
  productId: PropTypes.string,
  publisherId: PropTypes.string,
  sceneId: PropTypes.string,
  sceneMetadata: PropTypes.shape({
    adultContent: PropTypes.bool,
    audioUri: PropTypes.string,
    communicationACLEnabled: PropTypes.bool,
    coverPhotoUri: PropTypes.string,
    createdAt: PropTypes.string,
    creator: PropTypes.string,
    creatorId: PropTypes.string,
    description: PropTypes.string,
    districtId: PropTypes.string,
    districtSubdomain: PropTypes.string,
    groupCommunicationACL: PropTypes.arrayOf(PropTypes.string),
    groupPrivacyACL: PropTypes.arrayOf(PropTypes.string),
    owner: PropTypes.string,
    ownerId: PropTypes.string,
    photoGallery: PropTypes.arrayOf(PropTypes.string),
    premadeSceneId: PropTypes.string,
    privacyACLEnabled: PropTypes.bool,
    publishedAt: PropTypes.string,
    publishedPublic: PropTypes.bool,
    sceneId: PropTypes.string,
    slotId: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    thumbnailUri: PropTypes.string,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    userCommunicationACL: PropTypes.arrayOf(PropTypes.string),
    userPrivacyACL: PropTypes.arrayOf(PropTypes.string),
    visible: PropTypes.bool
  }),
  slotId: PropTypes.string,
  title: PropTypes.string,
  updatedAt: PropTypes.string
});
export default slotPropTypes;