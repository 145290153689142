import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { insertArgument } from '../../utils/string';
import defaultTranslations from './languages/en.json';

const TranslationContext = createContext();

// eslint-disable-next-line react/prop-types
const TranslationProvider = ({ language = 'en', children, ...rest }) => {
  const [translations, setTranslations] = useState(defaultTranslations);

  useEffect(() => {
    if (language !== 'en') {
      import(`./languages/${language}.json`)
        .then((res) => setTranslations(res.default))
        .catch(() => console.error(`Could not load translations for language ${language}`)); // eslint-disable-line no-console
    }
  }, [language]);

  const value = useMemo(() => ({ language, translations }), [language, translations]);

  return (
    <TranslationContext.Provider value={value} {...rest}>
      {children}
    </TranslationContext.Provider>
  );
};

const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }

  const { translations } = context;

  const getText = useCallback(
    (textKey, ...params) =>
      params.length === 0
        ? translations[textKey]
        : insertArgument(translations[textKey], ...params),
    [translations],
  );

  return { t: getText };
};

export { TranslationProvider, useTranslation };
