import { useTranslation } from '../../../../infrastructure/translation/TranslationProvider';
import { useUserContext } from '../../../../infrastructure/user-context';
import { useAxiosCallback } from '../../../../custom-hooks/useAxios';
import apis from '../../../../server/endpoints/apis';
import VisibilityToggle from './VisibilityToggle';

const SearchVisibility = () => {
  const { t } = useTranslation();
  const { userId, userData, setUserData } = useUserContext();

  const { executeRequest: updateSearchableProperty } = useAxiosCallback({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'patch',
    url: apis.userManagement.user(userId),
  });

  const handleUpdateSearchableProperty = (isSearchable) => {
    updateSearchableProperty({
      body: {
        searchable: isSearchable,
      },
      onSuccess: () => {
        setUserData((prev) => ({ ...prev, searchable: isSearchable }));
      },
    });
  };

  return (
    <VisibilityToggle
      title={t('SettingsPage.VisibilityTab.SearchVisibility.Title')}
      description={t('SettingsPage.VisibilityTab.SearchVisibility.Description')}
      isChecked={userData?.searchable}
      onChange={handleUpdateSearchableProperty}
    />
  );
};

export default SearchVisibility;
