import { useEffect, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
var useWavesurfer = function useWavesurfer(containerRef, _ref) {
  var height = _ref.height,
    waveColor = _ref.waveColor,
    progressColor = _ref.progressColor,
    url = _ref.url;
  var _useState = useState(null),
    wavesurfer = _useState[0],
    setWavesurfer = _useState[1];

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  useEffect(function () {
    if (!containerRef.current) {
      return undefined;
    }
    var ws = WaveSurfer.create({
      height: height,
      waveColor: waveColor,
      progressColor: progressColor,
      url: url,
      container: containerRef.current,
      barWidth: 1,
      barGap: 2,
      barRadius: 1
    });
    ws.load(url);
    setWavesurfer(ws);
    return function () {
      return ws.destroy();
    };
  }, [height, waveColor, progressColor, url, containerRef]);
  return wavesurfer;
};
export default useWavesurfer;